/*CSS Writen by shafique*/
.device_with_plan .radio-card .card-content-wrapper {
  border: none;
  padding: 0;
  width: auto;
  min-height: auto;
}
button.orange-filled-btn
{
  /* z-index: 9999999999999999999 !important;  */
}
.device_with_plan .card-content {
  display: flex;
  align-items: center;
  padding: 7px;
  background: none;
  border: 1px solid;
}
.mini-text-wrap .custom-control.overflow-checkbox .overflow-control-indicator {
  top: 10px;
}
a.link-Custom-Color
{
  color: #f36d25;
}
.device_with_plan label.radio-card .card-content img {
  width: 50px;
  height: 50px;
  margin-bottom: 0;
  margin-right: 5px;
}
/* .device_with_plan .grid-wrapper {
    grid-gap: 20px;
} */
.scoller_on_body .modal-common-body.modal-body {
  overflow: auto;
  max-height: calc(100vh - 140px);
}
.step3-leads-card_inner {
  max-height: calc(100vh - 350px);
  overflow: auto;
}
/* .deals-logo-wraper {
    height: 70px;
} */
.serivce-box {
  padding: 10px;
}
.device_with_plan .overflow-control-input {
  position: absolute;
}
.device_with_plan .radio-card {
  margin: 0;
}
.grid-wrapper.align_items_center {
  justify-content: center;
}

.step3-leads-card_inner::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.step3-leads-card_inner::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}

.step3-leads-card_inner::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #1a2978;
  border: 2px solid #555555;
}

/* Application stepform 1 style */
.tabs-radio-btns {
  margin-top: -42px;
}
.application-step-form-wraper {
  padding: 15px 0;
  position: relative;
  overflow: hidden;
  min-height: calc(100vh - 150px);
}

.application-step-form-wraper::before {
  background-image: url(../../../../assets/images/baner-left-curve.png);
  background-repeat: no-repeat;
  top: 140px;
  width: 100px;
  height: 380px;
  left: 0;
  position: absolute;
  content: "";
  background-size: contain;
}

.application-step-form-wraper::after {
  position: fixed;
  content: "";
  background-image: url(../../../../assets/images/baner-right-curve.png);
  background-repeat: no-repeat;
  width: 170px;
  height: 500px;
  background-size: contain;
  top: calc(100vh - 375px);
  right: 0;
}

.application-step1-top-content {
  padding-bottom: 30px;
  text-align: center;
}

.application-step1-top-content h4 {
  font-weight: normal;
}

.application-step1-top-content h3 {
  font-weight: bold;
}

.application-step1-bottom-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form_content.blue-form-box {
  background: #1a2a79;
  box-shadow: 0px 0px 20px #00000012;
  border-radius: 8px;
  padding: 40px 60px;
  color: #fff;
}

.step1-left-content {
  max-width: 924px;
  position: relative;
}

.step1-img-wraper img {
  height: 100%;
  border-radius: 8px;
  width: 100%;
}

.step1-left-content .terms-text a {
  margin-top: -44px;
  margin-left: 14px;
  text-decoration: none;
  font-size: 14px;
}

.step1-right-content {
  flex: 0 0 660px;
  margin-left: -200px;
  position: relative;
  max-width: 719px;
}

.form_content.blue-form-box .Submitting_form {
  font-size: 20px;
  color: #fff;
  font-weight: 300;
  line-height: 14px;
  font-weight: 600;
}
.form_content.blue-form-box .for-red {
  margin-top: 3px;
}
.form_content.blue-form-box .form-control {
  background-color: transparent;
  padding: 12px;
  color: #fff;
  font-size: 18px;
  border: 1px solid #d1d9ff;
  border-radius: 7px;
}

.form_content.blue-form-box ::placeholder {
  color: #fff;
}

.form_image img {
  border-radius: 27px;
  width: 100%;
}

.form_content select {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 10px;
  border: none;
  padding: 18px 30px;
  margin-top: 40px;
  color: #1a2a79;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 300;
  appearance: none;
  /* background: url(../../../assets/images/downarrow.svg); */
  background-repeat: no-repeat;
  background-position-x: calc(100% - 30px);
  background-position-y: calc(100% - 28px);
  font-weight: 600;
}

.blue-form-box .Submitting_form {
  color: #fff;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 300 !important;
  margin-left: 27px;
}

.blue-form-box .custom-control.overflow-checkbox .overflow-control-indicator {
  border: 2px solid #ffff;
}

.blue-form-box
  .custom-control.overflow-checkbox
  .overflow-control-indicator::after {
  border-bottom: 4px solid #fff;
  border-right: 4px solid #fff;
}

.blue-form-box
  .custom-control.overflow-checkbox
  .overflow-control-indicator::before {
  border-right: 7px solid #1a2a79;
}

.text-under-line {
  text-decoration: underline !important;
}

/* end */
/* leads step2 style */
.lead-step-form-card {
  background: #ffffff;
  box-shadow: 0px 0px 20px #00000012;
  border-radius: 8px;
  padding: 40px 20px;
  position: relative;
}

.lead-step-form-top-heading-content {
  max-width: 700px;
  margin: 0 auto 40px;
}

.lead-step-form-wraper {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 48px;
}

.lead-step-form-wraper small {
  font-size: 13px;
}

.top-text-wrapper code {
  font-size: 0.85em;
  background: linear-gradient(90deg, #fce3ec, #ffe8cc);
  color: #ff2200;
  padding: 0.1rem 0.3rem 0.2rem;
  border-radius: 0.2rem;
}

.tab-section-wrapper {
  padding: 30px 0;
}

.grid-wrapper {
  display: grid;
  grid-gap: 30px;
  place-items: center;
  place-content: start;
}

.grid-col-auto {
  grid-auto-flow: column;
  grid-template-rows: auto;
}

/* ******************* Main Styeles : Radio Card */
label.radio-card {
  cursor: pointer;
}

label.radio-card .card-content-wrapper {
  background: #fff;
  width: 150px;
  min-height: 140px;
  padding: 10px;
  display: grid;
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.04);
  transition: 200ms linear;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  position: relative;
}

label.radio-card .check-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  border: solid 2px #e3e3e3;
  border-radius: 50%;
  transition: 200ms linear;
  position: relative;
  position: absolute;
  right: -11px;
  top: -14px;
  content: "";
  opacity: 0;
}

.card-content {
  background: #ebebeb;
  border-radius: 8px;
  padding: 5px;
}

label.radio-card .check-icon:before {
  content: "";
  position: absolute;
  inset: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='12' height='9' viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.93552 4.58423C0.890286 4.53718 0.854262 4.48209 0.829309 4.42179C0.779553 4.28741 0.779553 4.13965 0.829309 4.00527C0.853759 3.94471 0.889842 3.88952 0.93552 3.84283L1.68941 3.12018C1.73378 3.06821 1.7893 3.02692 1.85185 2.99939C1.91206 2.97215 1.97736 2.95796 2.04345 2.95774C2.11507 2.95635 2.18613 2.97056 2.2517 2.99939C2.31652 3.02822 2.3752 3.06922 2.42456 3.12018L4.69872 5.39851L9.58026 0.516971C9.62828 0.466328 9.68554 0.42533 9.74895 0.396182C9.81468 0.367844 9.88563 0.353653 9.95721 0.354531C10.0244 0.354903 10.0907 0.369582 10.1517 0.397592C10.2128 0.425602 10.2672 0.466298 10.3112 0.516971L11.0651 1.25003C11.1108 1.29672 11.1469 1.35191 11.1713 1.41247C11.2211 1.54686 11.2211 1.69461 11.1713 1.82899C11.1464 1.88929 11.1104 1.94439 11.0651 1.99143L5.06525 7.96007C5.02054 8.0122 4.96514 8.0541 4.90281 8.08294C4.76944 8.13802 4.61967 8.13802 4.4863 8.08294C4.42397 8.0541 4.36857 8.0122 4.32386 7.96007L0.93552 4.58423Z' fill='white'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center center;
  transform: scale(1.6);
  transition: 200ms linear;
  opacity: 0;
}

label.radio-card input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

label.radio-card input[type="radio"]:checked + .card-content-wrapper {
  box-shadow: 0 2px 4px 0 rgba(219, 215, 215, 0.5), 0 0 0 2px #f36d25;
}

label.radio-card
  input[type="radio"]:checked
  + .card-content-wrapper
  .check-icon {
  background: #f36d25;
  border-color: #f36d25;
  transform: scale(1.2);
  opacity: 1;
}

label.radio-card
  input[type="radio"]:checked
  + .card-content-wrapper
  .check-icon:before {
  transform: scale(1);
  opacity: 1;
}

label.radio-card input[type="radio"]:focus + .card-content-wrapper .check-icon {
  box-shadow: 0 0 0 4px rgba(50, 102, 39, 0.2);
  border-color: #f36d25;
}

label.radio-card .card-content img {
  margin-bottom: 10px;
}

.small-device-wraper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.small-device-wraper img {
  width: 100px;
  height: 80px;
  object-fit: contain;
  display: flex;
  justify-content: center;
  align-items: center;
}

label.radio-card .card-content-wrapper.card-services-content-wrapper {
  border: 1px solid #a7a7a7;
  border-radius: 40px;
  width: 80px;
  height: 80px;
  min-height: unset;
  display: flex;
  justify-content: center;
  align-items: center;
}

label.radio-card
  .card-content-wrapper.card-services-content-wrapper
  .small-device-wraper
  img {
  width: 39px;
  height: 46px;
}

.fency-rounded-radio-button label.radio-card {
  text-align: center;
}

.fency-rounded-radio-button
  label.radio-card
  input[type="radio"]:checked
  + .card-content-wrapper {
  background: #f36d25;
  box-shadow: none;
}

.white-active-img {
  display: none !important;
}

.fency-rounded-radio-button
  label.radio-card
  input[type="radio"]:checked
  + .card-content-wrapper
  .non-active-img {
  display: none !important;
}

.fency-rounded-radio-button
  label.radio-card
  input[type="radio"]:checked
  + .card-content-wrapper
  .white-active-img {
  display: block !important;
}

label {
  color: #777;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.apply-program .form-control,
.form-select {
  border: 1px solid #a7a7a7;
  border-radius: 8px;
  height: 56px;
  color: #58595a !important;
  font-size: 16px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.lead-step-form-wraper
  .custom-control.overflow-checkbox
  .overflow-control-indicator,
.selct-plan-check-box
  .custom-control.overflow-checkbox
  .overflow-control-indicator {
  border-radius: 3px;
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #f36d25;
  cursor: pointer;
}

.lead-step-form-wraper
  .custom-control.overflow-checkbox
  .overflow-control-indicator::before,
.selct-plan-check-box
  .custom-control.overflow-checkbox
  .overflow-control-indicator::before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  transition: 0.3s;
  width: 10px;
  border-right: 7px solid #fff;
  border-radius: 3px;
  transform: rotateZ(45deg) scale(1);
  top: -4px;
  left: 5px;
  opacity: 2;
}

.lead-step-form-wraper
  .custom-control.overflow-checkbox
  .overflow-control-indicator::after,
.selct-plan-check-box
  .custom-control.overflow-checkbox
  .overflow-control-indicator::after {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  transition: 0.3s;
  transform: rotateZ(90deg) scale(0);
  width: 10px;
  border-bottom: 4px solid #f36d25;
  border-right: 4px solid #f36d25;
  border-radius: 3px;
  top: -2px;
  left: 2px;
}

/*CSS Writen by shafiq*/

/* .selct-plan-check-box {
    box-shadow: 0px 0px 14px #00000029;
    padding: 10px;
    border-radius: 42px;
    background: #fff;
} */

.selct-plan-check-box .overflow-control-description {
  position: relative;
  z-index: 1;
  padding: 0 10px;
  display: inline-block;
}
.selct-plan-check-box .overflow-control-description::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: -12px;
  height: 50px;
  background: #1a2978;
  z-index: -1;
  border-radius: 10px;
}
.selct-plan-check-box
  .custom-control.overflow-checkbox
  .overflow-control-indicator {
  border: 2px solid #ffffff;
  left: 10px;
  z-index: 9;
}
.selct-plan-check-box .overflow-control-description .orange-clr {
  color: #ffffff !important;
  line-height: 24px;
}
.selct-plan-check-box
  .custom-control.overflow-checkbox
  .overflow-control-indicator::before {
  border-right: 7px solid #1a2978;
}
.selct-plan-check-box
  .custom-control.overflow-checkbox
  .overflow-control-indicator::after {
  border-bottom: 4px solid #ffffff;
  border-right: 4px solid #ffffff;
}
.selct-plan-check-box
  .overflow-control-input:checked
  ~ .overflow-control-description::before {
  background: #f36d25;
}
.selct-plan-check-box
  .overflow-control-input:checked
  ~ .overflow-control-indicator::before {
  border-right: 7px solid #f36d25;
}

/*End CSS Writen by shafiq*/

.plan-selected {
  background: #ecf4eb;
}

/* end*/
/* step 3 style */
.leads-step3-wraper .card {
  background: #ffffff;
  border: 2px solid #f36d25;
  border-radius: 8px;
}
.leads-step3-wraper .card-body {
  padding: 20px;
  border-radius: 0 0 8px 8px;
}
.leads-step3-wraper .card-header {
  background: #f36d25;
  border-radius: 5px 5px 0px 0px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  -webkit-border-radius: 5px 5px 0px 0px;
  -moz-border-radius: 5px 5px 0px 0px;
  -ms-border-radius: 5px 5px 0px 0px;
  -o-border-radius: 5px 5px 0px 0px;
}

.serivce-box {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

ul.start-list {
  list-style: none;
  display: flex;
}

span.green-big-text {
  font-size: 38px;
  font-weight: 600;
  color: #f36d25;
  line-height: 35px;
}

ul.start-list li {
  padding-right: 10px;
  color: #efac5d;
}

.black-gradient {
  background: transparent linear-gradient(90deg, #525252 0%, #1c1c1c 100%);
}

.blue-gradiet {
  background: transparent linear-gradient(90deg, #005dac 0%, #0196f9 100%);
}

.purple-gradient {
  background: transparent linear-gradient(90deg, #493291 0%, #32206c 100%);
}

.pink-gradient {
  background: transparent linear-gradient(90deg, #e20074 0%, #a00052 100%);
}

.service-data {
  border-radius: 8px;
  padding: 8px 6px;
  color: #fff;
}

.simple-radio-butn input[type="radio"] {
  appearance: none;
  display: none;
}

.simple-radio-butn input[type="radio"] + label {
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  line-height: 45px;
}

.simple-radio-butn input[type="radio"] + label::before {
  content: "";
  display: inline-block;
  width: 20px;
  aspect-ratio: 1;
  border: 1px solid #f36d25;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  opacity: 1;
  transition: all 0.3s;
}

.simple-radio-butn input[type="radio"] + label::after {
  content: "";
  display: inline-block;
  width: 12px;
  aspect-ratio: 1;
  border: 1px solid #f36d25;
  background: #f36d25;
  border-radius: 50%;
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: all 0.3s;
}

.simple-radio-butn input[type="radio"]:checked + label::after {
  opacity: 1;
}

ul.radio-list.simple-radio-butn {
  list-style: none;
  display: flex;
  margin-top: 10px;
}

ul.radio-list li {
  display: flex;
  margin-left: 15px;
  align-items: center;
}

a.select-plan-text {
  color: #f36d25;
  text-decoration: none;
  font-size: 16px;
}

.order-submitted-inner {
  max-width: 734px;
}

.padding-70 {
  padding: 70px;
}
.leads-step3-wraper .lead-step-form-card {
  padding: 30px;
}

/* last step styling */
.lead-step-form-card p {
  font-size: 16px;
  line-height: 26px;
}

/* .contact-text{} */
.grey-bg-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 10px;
  max-width: 567px;
}

.grey-bg-card-content {
  background: #ebebeb;
  border-radius: 8px;
  padding: 20px;
}

h5.contact-text {
  font-size: 32px;
}

.top-green-border {
  border-top: 5px solid #f36d25;
}

.right-end-text {
  text-align: end;
  width: calc(100% - 170px);
}

/* laeds footer */
.leads-footer {
  padding: 10px 0;
}

ul.leads-footer-page {
  list-style: none;
  display: flex;
}

ul.leads-footer-page li a {
  text-decoration: none;
  color: #1a2a79 !important;
  text-transform: capitalize;
  font-size: 16px;
}

.line-bar {
  color: #a3a3a3 !important;
}

p.right-reserved {
  font-size: 16px;
  text-align: center;
}

.font-18 p {
  font-size: 18px;
}

ul.leads-footer-page li {
  padding: 0 5px;
}

.simple-radio-butn label {
  font-weight: normal;
  margin-bottom: 0;
}

.serivce-box p {
  line-height: 30px;
}

.selct-plan-check-box .Submitting_form {
  font-weight: 600 !important;
}

.service-data h1 {
  font-weight: 600;
  letter-spacing: -1.46px;
  font-size: 85px;
}

.service-data p {
  line-height: 15px;
}

.leads-step3-wraper .card-header h6 {
  font-size: 20px;
  font-weight: 600;
}

.mobile-viewdeals {
  display: none;
}

.modal-common-body {
  font-size: 22px;
  line-height: 26px;
}

.terms-text {
  margin-top: -47px;
  position: absolute;
}

ul.usage-list li {
  position: relative;
  padding-bottom: 10px;
  list-style-type: none;
}

.agreement-signature {
  max-width: 380px;
  height: 20px;
  border: none;
  font-size: 23px;
  font-style: italic;
  border-bottom: 1px solid #000;
  border-radius: 0;
  border-color: #000 !important;
}

.initial-lis .form-control:focus {
  box-shadow: 0 0 0 0;
}

ul.usage-list li label {
  font-weight: normal;
  color: #000;
  margin-bottom: 0;
}

.information-btn button {
  width: 40px;
  height: 40px;
  border: 1px solid #1a2a79;
  border-radius: 50px;
  font-size: 30px;
  background: transparent;
  color: #1a2a79;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deals-logo-wraper {
  height: 90px;
}

.deals-logo-wraper img {
  height: 100%;
  object-fit: contain;
}

.no-deal-found {
  min-height: calc(100vh - 395px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.no-deal-image {
  width: 270px;
  margin-bottom: 20px;
}

.text-decoration-none {
  text-decoration: none;
}

.custom-toggle input[type="checkbox"].toggle {
  opacity: 0;
  position: absolute;
  left: -99999px;
}

.custom-toggle input[type="checkbox"].toggle + label {
  height: 31px;
  line-height: 30px;
  background-color: transparent;
  padding: 0px 7px;
  border-radius: 16px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: all 0.25s ease-in;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.5);
  border: 1px solid #ffffff9a;
  border-radius: 38px;
  font-size: 14px;
  font-weight: normal;
}

.custom-toggle input[type="checkbox"].toggle + label:before,
input[type="checkbox"].toggle + label:hover:before {
  content: " ";
  position: absolute;
  top: 2px;
  left: 4px;
  width: 25px;
  height: 25px;
  background: #fff;
  z-index: 2;
  transition: all 0.25s ease-in;
  border-radius: 19px;
}

.custom-toggle input[type="checkbox"].toggle + label .off,
input[type="checkbox"].toggle + label .on {
  color: #fff;
}

.custom-toggle input[type="checkbox"].toggle + label .off {
  margin-left: 46px;
  display: inline-block;
}

.custom-toggle input[type="checkbox"].toggle + label .on {
  display: none;
}

.custom-toggle input[type="checkbox"].toggle:checked + label .off {
  display: none;
}

.custom-toggle input[type="checkbox"].toggle:checked + label .on {
  margin-right: 39px;
  display: inline-block;
  color: #f36d25;
}

.custom-toggle input[type="checkbox"].toggle:checked + label,
input[type="checkbox"].toggle:focus:checked + label {
  background-color: #fff;
}

.custom-toggle input[type="checkbox"].toggle:checked + label:before,
input[type="checkbox"].toggle:checked + label:hover:before,
input[type="checkbox"].toggle:focus:checked + label:before,
input[type="checkbox"].toggle:focus:checked + label:hover:before {
  background-position: 0 0;
  top: 2px;
  left: 100%;
  margin-left: -30px;
  background: #f36d25;
}

.service-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #cccccc40;
  margin-top: 5px;
  padding-top: 10px;
  width: 100%;
}

.h-20 {
  height: 20px;
}
.custom-optin-modal .modal-dialog {
  display: flex;
  align-items: center;
  height: 100vh;
  margin-top: 0;
}
.small-text2 {
  font-size: 10px;
  line-height: 0;
}
.small-text2{font-size: 10px;line-height: 0;}

.custom-chat-box ul.chat-messages li {
    display: inline-block;
    padding: 11px;
    border-radius: 30px 30px 0 30px;
    margin-bottom: 16px;
    color: #fff;
    max-width: 600px;
}

.custom-chat-box ul.chat-messages .first-chat {
    background-color: #c5c5c5;
    border-radius: 30px 30px 30px 0px;
}

.custom-chat-box ul.chat-messages .second-chat {
    background-color: #3eba81;
    align-self: flex-end; 
    text-align: right; 
    border-radius: 30px 30px 0 30px; 
}
.custom-chat-box {
    border: 1px solid #80808040;
    padding: 29px 10px 18px 18px;
    border-radius: 11px;
    height: 250px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    background-color: #f2f1f154;

}

.chat-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-top: 15px;
}

.chat-box button {
    position: absolute;
    right: 0;
    top: 0px;
    height: 50px;
    border-radius: 0 8px 8px 0;
}



.chat-box input:focus {
    box-shadow: none;
}

.custom-chat-box ul.chat-messages {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 10px!important;
    flex: 1; 
    list-style-type: none; 
    padding: 0;
    margin: 0;
    overflow-y: auto; 
}

.chat-box input[type="text"] {
    flex: 1; 
    border-radius: 8px;
    margin-right: 10px; 
    border-radius: 8px;
    height: 50px;
    padding-right: 45px;
}
.chat-box svg{
    width: 20px;
    height: 20px;
    fill: #fff;
}
.custom-chat-box ::-webkit-scrollbar{
    width: 5px;
    height: 20px;
    border-radius: 10px;
}
.custom-chat-box ::-webkit-scrollbar-track{
    background-color: gray;
    border-radius: 10px;
}
.custom-chat-box ::-webkit-scrollbar-thumb{
    height: 20px;
    background-color: #1a2a79;
    border-radius: 10px;
}
.deals-step-screen label.radio-card .card-content-wrapper.card-services-content-wrapper {
  width: 50px;
  height: 50px;
}
.deals-step-screen .leads-step3-wraper .lead-step-form-card {
  padding: 15px 15px;
}
.deals-step-screen .leads-step3-wraper .card-header {
  padding: 5px 10px;
}
.deals-step-screen .step3-leads-card_inner {
  max-height: calc(100vh - 295px);
}
.deals-step-screen .leads-step3-wraper .card-body {
  padding: 7px 10px 15px;
}
.deals-step-screen label.radio-card .card-content-wrapper.card-services-content-wrapper .small-device-wraper img {
  width: 39px;
  height: 33px;
}
.congratulation-modal .modal-content{
/* min-height: 400px;
background-size: cover;
background-position: center;
border-radius: 30px;
border: 5px solid white; */
padding: 20px 40px 20px;
margin: 40px auto;
}
.congratulation-modal.last_step_congratulation_modal .modal-dialog{
  max-width: 1700px;
  width: 100%;
  padding: 0 20px;
}
.congratulation-modal.last_step_congratulation_modal .sw-theme-dots>ul.step-anchor>li>a:before {
  content: ' ';
  position: absolute;
  bottom: -17px;
  left: 40%;
  margin-top: 10px;
  display: block;
  border-radius: 50%;
  color: #428bca;
  background: #f5f5f5;
  border: none;
  width: 30px;
  height: 30px;
  text-decoration: none;
  z-index: 98;
  z-index: 96;
  display: block;
  FONT-SIZE: 20PX;
  font-weight: 600;
}
.congratulation-modal.last_step_congratulation_modal .modal-content {
  padding: 20px 40px 20px;
  margin: 130px auto 40px
}
/* .congratulation-modal h6{
color: #fff;
font-weight: 600;
position: absolute;
top: -9%;
left: 3%;
width: 94%;
text-align: left;
background: #234678;
padding: 31px;
border-radius: 11px;
}
.congratulation-modal-header h5{
  position: absolute;
  top: -26px;
  right: 50px;
  color: #fff;
} */
.custom-out-of-box-header{

  color: #fff;
  font-weight: 600;
  position: absolute;
  top: -100px;
  left: 0%;
  width: 94%;
  text-align: left;
  background: #234678;
  padding: 31px;
  border-radius: 11px 11px 0 0;
  width: 100%;
  text-align: center;
}
.congratulation-modal.last_step_congratulation_modal h6{
  margin-top: 0;
}
.modal-box-custom{
/* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
max-width: 600px;
width: 100%;
padding: 10px;
margin: 48px auto 10px;
border-radius: 10px;
position: relative;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
/* min-height: 450px; */
}
/* .modal-box-custom::before{
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 4px solid #052c65;
  transform: rotate(45deg);
  border-top: 0;
  border-right: 0;
  left: -60px;
  top: 50%;
  cursor: pointer;
} */
/* .modal-box-custom::after{
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  border: 4px solid #052c65;
  transform: rotate(229deg);
  border-top: 0;
  border-right: 0;
  right: -73px;
  top: 50%;
  cursor: pointer;
} */
.congratulation-modal.last_step_congratulation_modal .item_cong img {
  max-width: 100%;
  height: 300px;
  width: 100%;
  object-fit: contain;
}
.last_step_congratulation_modal p.learn-more {
  margin: 15px 26px 14px 0px;
  text-align: left;
  color: #f36d25;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 24px;
  cursor: pointer;
}
.last_step_congratulation_modal .congratulation-modal-header h5 a{
  text-decoration: none;
}

.last_step_congratulation_modal .congratulation-modal-header  p {
  color: #fff;
  margin-bottom: 0 !important;
}
span.theme-color-green {
  color: #0fdd6d;
  font-size: 32px;
  font-weight: 700;
  border-radius: 10px;
  margin-left: 5px;
  padding: 2px;
}
.congratulation-modal.last_step_congratulation_modal .item_cong{
  margin-top: 0;
}
.congratulation-modal .sw-theme-dots>ul.step-anchor>li.active>a:before{ 
  box-shadow: rgba(0, 0, 0, 0.27) 0px 3px 8px;
}
.congratulation-modal .sw-theme-dots>ul.step-anchor li:before {
  content: " ";
  position: absolute;
  top: unset;
  bottom: -7px;;
  width: 100%;
  height: 5px;
  background-color: #f5f5f5;
  border-radius: 3px;
  z-order: 0;
  z-index: 95;
  left: 3px;
}
.last_step_congratulation_modal .learn-more svg{
transform: rotate(269deg)

}
.last_step_congratulation_modal .learn-more svg path{
color:#f36d25;
stroke: #f36d25;
}
.last_step_congratulation_modal .congratulation-modal-header{
  padding: 30px;
}
.congratulation-modal.last_step_congratulation_modal h6 {
  text-align: center;
  line-height: 42px;
  margin-bottom: 5px;
  font-size: 36px;
}
.congratulation-modal.last_step_congratulation_modal .sw-theme-dots>ul.step-anchor>li>a:after {
  content: ' ';
  position: relative;
  left: 46%;
  bottom: -17px;
  margin-top: 10px;
  display: block;
  width: 15px;
  height: 15px;
  background: #d1d1d1;
  border-radius: 50%;
  z-index: 99;
}
.text-btn-wrap {
  margin: 30px auto 35px auto;
  text-align: center;
}
.text-btn-wrap button:nth-child(1){
  max-width: 170px;
  width: 100%;
  background: #052c65;
  border: 1px solid #052c65;
  font-size: 27px;
  height: 55px;
  margin-right: 20px;
}
.modal-box-custom.offers-box {
  margin-top: 80px;
  min-height: 461px;
}
.text-btn-wrap button:nth-child(1):hover{

  width: 100%;
  background: #fff!important;
  color:#052c65 ;
  border: 1px solid #052c65;
  }
.text-btn-wrap button:nth-child(2){
  max-width: 170px;
  width: 100%;
  background: #f36d25;
  border: 1px solid #f36d25;
  font-size: 27px;
  height: 55px;
  }
  .text-btn-wrap button:nth-child(2):hover{
    
    width: 100%;
    background: #fff;
    border: 1px solid #f36d25;
    color:#f36d25 ;
    }
.modal-box-custom .text-section h6 {
  font-size: 23px;
  color: #6c6d6d;
  text-align: left;
  line-height: 30px;

}
.modal-box-custom .text-section p{
  color: #052c65;
}
.congratulation-modal.last_step_congratulation_modal .sw-theme-dots>ul.step-anchor>li.active>a:after {
  background: #fff;
}
.congratulation-modal.last_step_congratulation_modal  .sw-theme-dots>ul.step-anchor>li.active>a:before {

  background: #04a64f;
}
.congratulation-modal .sw-theme-dots ul{
  border-bottom: 0;
  justify-content: center;
  position: relative;
}
.congratulation-modal .sw-theme-dots>ul.step-anchor>li>a {
  position: relative;
  text-align: center;
  font-weight: 700;
  background: 0 0;
  border: none;
  color: #1a2a79;
  text-decoration: none;
  outline-style: none;
  z-index: 96;
  display: block;
  font-size: 20px;

}
.congratulation-modal .sw-theme-dots>ul.step-anchor>li {
  border: none;
  position: relative;
}

.congratulation-modal  .item_cong {
  margin: 50px 5px 5px 5px ;
  TEXT-ALIGN: center;

}
.congratulation-modal  .item_cong img {
  max-width: 298px;
  height: 137px;
  width: 100%;
  object-fit: contain;

}
.congratulation-modal .dual-btn-wrap {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.congratulation-modal .dual-btn-wrap button{
  margin-left: 10px;
}
.congratulation-modal.last_step_congratulation_modal  h2 {
font-size: 32px;
line-height: 34px;
text-align: center;
color: #052c65;
font-weight: 700;
font-size: 39px;
}
.congratulation-modal  h6{
  font-size: 26px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  margin-top: 30px;
}

.last_step_congratulation_modal .congratulation-modal-header {
  background: unset;
  border-radius: 11px;
  padding: 15px;
 margin-top:54px;
}
.netspend-visa-wrapper .netspend-main-content p {
  text-align: left;
  color: #19277B;
  font-size: 17px;
  line-height: 22px;
  margin: 14px 0;
  font-weight: 600;
}
.congratulation-modal.last_step_congratulation_modal  h2 {
  font-size: 32px;
  line-height: 34px;
  text-align: center;
  font-size: 32px;
  line-height: 34px;
  color: #052c65;
  text-align: center;
  font-weight: 700;
  position: relative;
  }
  .congratulation-modal.last_step_congratulation_modal  h2::after{
    content: "";
    position: absolute;
    width: 36%;
    border: 3px solid #052c65;
    left: 32%;
    bottom: -11px;
  }
.congratulation-modal.last_step_congratulation_modal .btn-close{
  position: absolute;
  top: -100px;
  right: 0;
  font-size: 12px;
  width: 18px;
  height: 18px;
  display: none;
  background: #03a84e;
  display: flex;
  justify-content: center;
  align-items: end;
  background-size: 17px;
  background-repeat: no-repeat;
  background-position: center;
}
.congratulation-modal.last_step_congratulation_modal .modal-header{
  background-color: unset;
  display: block;
  border-radius: 10px;
  border-bottom: 0;
}
.congratulation-modal.last_step_congratulation_modal button.right-arrow {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 8px solid #052c65;
  transform: rotate(229deg);
  border-top: 0;
  border-right: 0;
  right: 400px;
  top: 50%;
  cursor: pointer;
  background: unset;
}
.modal-box-custom.offers-box .text-section h3{
  font-size: 32px;
  color: #052c65;
  line-height: 42px;
  font-weight: 700;
  max-width: 713px;
  margin-bottom: 7px;
  text-transform: capitalize;

}
.modal-box-custom.second-offers-box  .text-section h3 {
  font-size: 35px;
  line-height: 45px;
  margin-bottom: 20px;
  color: #1a2a79;
  font-weight: 700;

}
.modal-box-custom.offers-box {
  margin-top: 80px;
}
.congratulation-modal.last_step_congratulation_modal button.left-arrow {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 8px solid #052c65;
  transform: rotate(41deg);
  border-top: 0;
  border-right: 0;
  left:400px;
  top: 50%;
  cursor: pointer;
  background: unset;
}
.congratulation-modal .orange-filled-btn {
  font-size: 19px;
  font-weight: 600;
  padding: 10px 15px;
}
.congratulation-modal .theme-blue-filled{
border: 1px solid #1a2978;
background: #1a2978;
}
.congratulation-modal .theme-blue-filled:hover{
  background: #fff;
  border: 1px solid #1a2978;
  color: #1a2978;
}
.congratulation-modal p{
  text-align: center;
  font-size: 20px;
  margin-bottom: 20px;
  max-width: 700px;
  margin: 0 auto 33px;
  line-height: 25px;
}
.form_content_banner .css-1wlpnw2-control.is-invalid
{
  border: 1px solid red;
}
.form_content_banner .css-1wlpnw2-control.is-invalid:hover
{
  border: 1px solid red;
}
 .form_content_banner .css-1wlpnw2-control.css-b62m3t-container{
  padding: 0;
}
.form_content_banner .css-1wlpnw2-control,.form_content_banner .css-7cav7t-control{
  width: 100%;
}
.form_content.blue-form-box .form-control.appear_none {
  background-position-y: calc(100% - 23px);
  font-weight: 400;
  background-position-x:calc(100% - 15px) ;
  padding-right: 21px;
}
.form_content.blue-form-box .form-control.appear_none option{
  color: #000!important;
}
.deals-custom-wrap-screen .form-control.is-invalid {
  background-position: right calc(0.375em + -0.125rem) center;
  padding-right: 22px !important;
}

.deals-custom-wrap-screen .for-red {
  font-weight: 700;
  font-size: 15px;
}
.deals-custom-wrap-screen .form_content.blue-form-box .form-control {
  background-color: #fff;
  color: #000;
  /* background-position-y: calc(100% - 24px); */
  font-weight: 400;
  background-position-x: calc(100% - 6px);

}
.deals-custom-wrap-screen .form_content.blue-form-box .form-control::placeholder{
  color: #000;
}
/* .deals-custom-wrap-screen .form_content.blue-form-box .form-control::placeholder{
  font-weight: bold;
} */
.deals-custom-wrap-screen .step1-right-content {
  flex: 0 0 700px;
  max-width: 700px;
}
.congratulation-modal.last_step_congratulation_modal .w_10{
  width: 11%;
  padding: 10px;
  margin: 19px 0;
}
h2.access-horoscope {
  color: #1a2a79;
  font-size: 32px;
  margin: 21px 0;
}.last_step_congratulation_modal h5{
  font-size: 23px;
  margin-bottom: 10px;
}
.congratulation-modal.last_step_congratulation_modal .modal-content {
  border-radius: 0 0 10px 10px;
}
.fade.congratulation-modal.last_step_congratulation_modal.modal.show {
  padding-left: 0!important ;
}
.congratulation-modal.last_step_congratulation_modal .f_21{
  font-size: 21px;
}
.congratulation-modal.last_step_congratulation_modal .custom-out-of-box-header h6{
  margin-bottom: 10px;
}
svg.theme-color-svg path {
  fill: #fff;
}
.custom-out-of-box-header svg{
  margin-top: -10px;
}
.paymeny-deatail-header {
  background: #135c9a;
  padding: 15px;
  border-radius: 15px 15px 0 0;
}
.paymeny-deatail-header h4{
  text-align: center;
  color: #fff;
  font-size: 25px;
}
.payment-detail-modal .stripe_field_wrap {
  background: unset;
  padding: 21px;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 30px;
  margin:6px 0 0px 0;
  cursor: pointer;
}
.payment-detail-modal h5{
  font-size: 22px;
  font-weight: 500;
  color: #19277B;
  text-align: left;

}
.payment-detail-modal label{
  font-size: 20px;
  margin-top: 34px;
}

.payment-detail-modal .modal-common-body.modal-body {
  padding: 0;
}
.payment-detail-modal .modal.show .modal-dialog {
  transform: none;
  display: flex;
  align-items: center;
  height: 100vh;
  margin-top: 0;
}
.payment-detail-modal .modal.show .modal-dialog {
  transform: none;
  display: flex;
  align-items: center;
  height: 100vh;
}
.fade.common-modal-wrape.payment-detail-modal.modal.show {
  display: flex!important;
  align-items: center;
  height: 100vh;
}
.thanku-screen .congratulation-modal.last_step_congratulation_modal li.nav-item.active a{
  color: green;
}
.thanku-screen.inserted-thank-screen .congratulation-modal.last_step_congratulation_modal button.left-arrow {
  left: 29px;
}
.thanku-screen.inserted-thank-screen .congratulation-modal.last_step_congratulation_modal button.right-arrow {
  right: 29px;
}
.thanku-screen.inserted-thank-screen .congratulation-modal.last_step_congratulation_modal h2 {
  font-size: 27px;
  margin-bottom: 20px;

}
.thanku-screen.inserted-thank-screen .congratulation-modal .sw-theme-dots>ul.step-anchor>li>a {
  font-size: 17px;
}
.thanku-screen.inserted-thank-screen .thanku-screen .modal-box-custom.second-offers-box .text-section h3 {
  font-size: 23px;
  line-height: 26px;
  margin-bottom: 9px;
}
.padding-70.p-23{
  padding-top: 23px;
}
.thanku-screen.inserted-thank-screen .grey-bg-card-content {
  padding: 10px 20px;
}
.thanku-screen.inserted-thank-screen .text-btn-wrap button {
  max-width: 108px;
  font-size: 22px;
  height: 45px;
}
.thanku-screen.inserted-thank-screen  .text-btn-wrap {
  margin: 0px auto 0px auto;
}
.thanku-screen.inserted-thank-screen .grey-bg-card-content {
  padding: 4px 20px;
}
.thanku-screen.inserted-thank-screen .modal-box-custom.second-offers-box .text-section h3 {
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 3px;
}

.inserted-second-view .form-check-input:checked,.inserted-order-2 .form-check-input:checked{
  background-color: #04a64f;
  border-color: #04a650;
  border: 0!important;
}
.form-control.form-custom-date {
  height: 48px;
}
.inserted-second-view .form-check .form-check-input[type=radio],.inserted-order-2 .form-check .form-check-input[type=radio] {
  transform: scale(1.1);
  border: 1px solid grey;
  margin-left: -23px;
}
.inserted-second-view  .grey-bg-card-content {
  padding: 10px 14px;
}
.top-green-border.inserted-box{
  background: #dddddd0d;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin-top: 10px;

}
.inserted-display-block{
  display: none;
}
.textual-display-none{
  display: none;
}
.inserted-order-2 .order-submitted-inner {
  max-width: 879px;
}
.installation-mobile-date .orange-filled-btn {
  font-size: 18px;
  line-height: 20px;
  padding: 12px 25px;
}
.orange-clr{
  color: #f36d25;
}
.orange-filled-btn{
  border: 1px solid #f36d25;
  border-radius: 14px;
  background: #f36d25;
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 18px;
   padding: 12px 25px;
  line-height: 20px;

}
.orange-filled-btn:hover{
  background: #fff;
  color: #f36d25;
  border-color: #f36d25;
}
.mini-text-wrap p{
  font-size: 12px;
  line-height: 14px;
}
ul.leads-footer-page.updated-footer {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  row-gap: 6px;
  column-gap: 18px;
  margin-bottom: 12px;
}
ul.leads-footer-page.updated-footer  li{
  position: relative;
  border-left:1px solid #ddd ;
  padding-left: 12px;
}
/* ul.leads-footer-page.updated-footer .border_left{
  border-left:none!important ;
} */

@media(max-width:1740px){
  .deals-step-screen .step3-leads-card_inner {
    max-height: calc(100vh - 278px);
}
.congratulation-modal.last_step_congratulation_modal .modal-dialog {
  max-width: 1400px;
}
.modal-box-custom {
  max-width: 569px;
}
.congratulation-modal.last_step_congratulation_modal .item_cong img {
  height: 270px;
}
.congratulation-modal.last_step_congratulation_modal button.right-arrow {
  right: 300px;
}
.congratulation-modal.last_step_congratulation_modal button.left-arrow{
left: 300px;
}
ul.leads-footer-page.updated-footer {
  grid-template-columns: repeat(5, 1fr);
}
}

@media only screen and (max-width: 1600px) {
  .service-data {
    padding: 15px;
  }

  .deals-logo-wraper {
    height: 78px;
  }

  ul.usage-list li label {
    font-size: 14px;
    line-height: 22px;
  }

  .modal-header .btn-close {
    background-size: 34%;
    width: 22px;
    height: 22px;
  }

  label.radio-card .card-content-wrapper {
    min-height: 100px;
  }

  .small-device-wraper img {
    height: 58px;
  }

  .lead-step-form-wraper {
    padding: 30px;
  }

  .lead-step-form-top-heading-content {
    margin: 0 auto 30px;
  }

  .lead-step-form-card {
    padding: 30px 20px;
  }

  label {
    font-weight: 600;
    margin-bottom: 5px;
  }

  .blue-form-box .Submitting_form {
    font-size: 16px;
    margin-left: 27px;
    line-height: 32px;
  }

  .step1-right-content {
    flex: 0 0 464px;
    margin-left: -200px;
    max-width: 719px;
  }

  .form_content.blue-form-box {
    padding: 27px 37px;
  }

  .step1-left-content {
    max-width: 850px;
  }

  p.right-reserved {
    font-size: 15px;
  }

  ul.leads-footer-page li a {
    font-size: 14px;
  }

  ul.leads-footer-page li {
    padding: 0 3px;
  }

  .serivce-box {
    padding: 10px;
  }

  .simple-radio-butn label {
    font-size: 14px;
  }

  .service-data h4 {
    font-size: 30px;
    line-height: 44px;
  }

  .application-step-form-wraper::before {
    top: 100px;
    height: 280px;
  }

  .lead-step-form-card p {
    font-size: 15px;
    line-height: 23px;
  }

  h5.contact-text {
    font-size: 28px;
  }

  .grey-bg-card-content {
    padding: 15px;
  }

  .form_content label {
    font-size: 16px;
    line-height: 21px;
  }

  .form_content .form-control,
  .form_content select {
    padding: 15px 13px;
    font-size: 16px;
  }

  .form_content select {
    margin-top: 20px;
    background-position-x: calc(100% - 19px);
    background-position-y: calc(100% - 20px);
  }

  .Submitting_form {
    font-size: 16px;
    line-height: 22px;
  }

  .form_content {
    padding-top: 50px;
  }

  .service-data h1 {
    font-size: 80px;
  }

  .service-data h4 {
    font-size: 28px;
    line-height: 38px;
  }

  .service-data p {
    line-height: 17px;
  }

  .mobile-nbr small {
    font-size: 12px;
    line-height: 14px;
  }
  .deals-step-screen label.radio-card .card-content-wrapper.card-services-content-wrapper {
    width: 40px;
    height: 40px;
    margin: auto;
  }
  .deals-step-screen .step3-leads-card_inner {
    max-height: calc(100vh - 220px);
  }
  
  .leads-step-header.site-header .navbar {
    padding: 5px 0;
  }
  .deals-step-screen .leads-step3-wraper .lead-step-form-card {
    padding: 7px 15px;
  }
  .deals-step-screen .theme-btn, .green-borderd-btn {
    padding: 7px 15px;
    font-size: 16px;
  }
  .deals-step-screen .orange-filled-btn, .theme-btn {
    font-size: 15px;
    padding: 7px 10px;
  }
  .deals-step-screen  .device_with_plan label.radio-card .card-content img {
    width: 40px;
    height: 40px;
  }
  .deals-step-screen .leads-step3-wraper .card-body {
    padding: 7px 10px 20px;
}
.form_content.blue-form-box .form-control.appear_none {
  background-position-y: calc(100% - 15px);
}
.deals-custom-wrap-screen .for-red {
  font-size: 14px;
}
.congratulation-modal.last_step_congratulation_modal button.left-arrow {
  left:200px;
}
.congratulation-modal.last_step_congratulation_modal button.right-arrow {
  right:200px;
}
.modal-box-custom.second-offers-box .text-section h3 {
  font-size: 31px;
}
.congratulation-modal.last_step_congratulation_modal .custom-out-of-box-header h6 {
  font-size: 32px;
}
.custom-out-of-box-header {
  padding: 20px;
}
.congratulation-modal.last_step_congratulation_modal .congratulation-modal-header h2 {
  font-size: 29px;
}
.thanku-screen .congratulation-modal.last_step_congratulation_modal h2,.thanku-screen.inserted-thank-screen .congratulation-modal.last_step_congratulation_modal h2 {
  font-size: 24px;
}
.thanku-screen .congratulation-modal.last_step_congratulation_modal .custom-out-of-box-header h6,.thanku-screen.inserted-thank-screen .congratulation-modal.last_step_congratulation_modal .custom-out-of-box-header h6{
  font-size: 26px;
}
.thanku-screen .modal-box-custom.second-offers-box .text-section h3,.thanku-screen.inserted-thank-screen .modal-box-custom.second-offers-box .text-section h3{
  font-size: 24px!important;
}
.thanku-screen  .congratulation-modal.last_step_congratulation_modal h6,.thanku-screen  .congratulation-modal.last_step_congratulation_modal span {
  font-size: 18px;
  line-height: 24px;
}
.thanku-screen label {
  font-size: 14px;
}
ul.leads-footer-page.updated-footer {
  grid-template-columns: repeat(3, 1fr);
}
}

@media only screen and (max-width: 1500px) {
  .service-data h1 {
    font-size: 60px;
  }

  .blue-form-box .Submitting_form {
    line-height: 18px;
  }

  .form_content.blue-form-box .Submitting_form {
    line-height: 0;
  }

  label.radio-card .card-content-wrapper {
    padding: 5px;
  }

  .lead-step-form-wraper small {
    font-size: 12px;
  }

  .h-20 {
    height: 16px;
  }
  .deals-custom-wrap-screen .step1-right-content {
    flex: 0 0 700px;
    max-width: 700px;
  }
  .congratulation-modal.last_step_congratulation_modal .modal-dialog {
    max-width: 1200px;
  }
  .congratulation-modal.last_step_congratulation_modal .item_cong img {
    height: 250px;
    object-fit: cover;
}
.modal-box-custom.second-offers-box .text-section h3 {
  font-size: 29px;
}
.modal-box-custom {
  max-width: 600px;
}
.congratulation-modal.last_step_congratulation_modal .modal-dialog {
  max-width: 1200px;
}


}

@media only screen and (max-width: 1367px) {
  .small-text2 {
    font-size: 9px;
  }

  .service-data h1 {
    font-size: 55px;
    line-height: 64px;
  }

  .service-card-footer {
    padding-top: 7px;
  }

  .custom-toggle input[type="checkbox"].toggle + label .off {
    margin-left: 26px;
  }

  .custom-toggle input[type="checkbox"].toggle:checked + label .on {
    margin-right: 26px;
  }

  .custom-toggle input[type="checkbox"].toggle + label:before,
  input[type="checkbox"].toggle + label:hover:before {
    width: 20px;
    height: 20px;
  }

  .custom-toggle input[type="checkbox"].toggle:checked + label:before,
  input[type="checkbox"].toggle:checked + label:hover:before,
  input[type="checkbox"].toggle:focus:checked + label:before,
  input[type="checkbox"].toggle:focus:checked + label:hover:before {
    top: 3px;
    margin-left: -23px;
  }

  .custom-toggle input[type="checkbox"].toggle + label {
    font-size: 14px;
    height: 26px;
    line-height: 25px;
  }

  .form_content.blue-form-box .Submitting_form {
    font-size: 15px;
    line-height: 2px;
  }

  .deals-logo-wraper {
    height: 55px;
  }

  .step1-left-content {
    max-width: 700px;
  }

  .blue-form-box .Submitting_form {
    font-size: 14px;
    line-height: 19px;
  }

  .form_content.blue-form-box .form-control {
    padding: 10px;
    font-size: 15px;
  }

  .leads-step3-wraper .card-header {
    padding: 7px;
  }

  .leads-step3-wraper .card-header h6 {
    font-size: 16px;
  }

  .service-data {
    padding: 7px 6px;
  }

  .font-18 p {
    font-size: 16px;
  }

  .green-rounded-btn {
    padding: 8px 12px;
    font-size: 14px;
  }

  /* .selct-plan-check-box {
        padding: 8px 10px;
        line-height: 21px;
    } */

  .simple-radio-butn input[type="radio"] + label {
    padding-left: 25px;
    line-height: 36px;
  }

  .plan-check-box
    .custom-control.overflow-checkbox
    .overflow-control-indicator {
    top: 2px;
  }

  .application-step-form-wraper::after {
    width: 100px;
    height: 400px;
    top: calc(100vh - 276px);
  }

  .padding-70 {
    padding: 45px;
  }

  h5.contact-text {
    font-size: 24px;
  }

  .grey-bg-card {
    max-width: 500px;
  }

  .lead-step-form-card p {
    font-size: 14px;
    line-height: 20px;
  }

  .form_content label {
    font-size: 15px;
    line-height: 18px;
  }

  .form_content .form-control,
  .form_content select {
    padding: 12px 13px;
    font-size: 15px;
  }

  .form_content select {
    margin-top: 20px;
    background-position-x: calc(100% - 14px);
    background-position-y: calc(100% - 18px);
  }

  .Submitting_form {
    font-size: 14px;
    line-height: 18px;
  }

  .form_content {
    padding-top: 40px;
  }

  .terms_condition {
    margin-top: 20px;
  }

  .serivce-box p {
    font-size: 13px;
  }

  .service-data p {
    line-height: 15px;
    font-size: 13px;
  }
  .deals-step-screen .step3-leads-card_inner {
    max-height: calc(100vh - 214px);
}
.deals-step-screen .leads-step3-wraper .card-body {
  padding: 7px 10px 15px;
}
.deals-step-screen label.radio-card .card-content-wrapper.card-services-content-wrapper .small-device-wraper img {
  width: 36px;
  height: 27px;
}
.deals-step-screen.wiresless-deals-viewport .step3-leads-card_inner {
  max-height: calc(100vh - 198px);
}
.deals-step-screen .custom-container {
  padding: 0 7.5%;
}
  /* .selct-plan-check-box .custom-control.overflow-checkbox .overflow-control-indicator {
        top: 2px;
    } */
    .congratulation-modal.last_step_congratulation_modal .item_cong img {
      height: 230px;
    }
    .thanku-screen.inserted-thank-screen .congratulation-modal .sw-theme-dots>ul.step-anchor>li>a {
      font-size: 15px;
    }
    .thanku-screen .modal-box-custom.second-offers-box .text-section h3, .thanku-screen.inserted-thank-screen .modal-box-custom.second-offers-box .text-section h3 {
      font-size: 21px !important;
    }
    .thanku-screen .netspend-visa-wrapper .netspend-main-content p, .thanku-screen.inserted-thank-screen .netspend-visa-wrapper .netspend-main-content p {
      font-size: 15px;
      line-height: 22px;
      margin: 7px 0;
    }  
 
    .thanku-screen .congratulation-modal.last_step_congratulation_modal h2,.thanku-screen.inserted-thank-screen .congratulation-modal.last_step_congratulation_modal h2 {
      font-size: 20px;
    }
    .thanku-screen .congratulation-modal.last_step_congratulation_modal .custom-out-of-box-header h6,.thanku-screen.inserted-thank-screen .congratulation-modal.last_step_congratulation_modal .custom-out-of-box-header h6{
      font-size: 24px;
    }
    .thanku-screen .modal-box-custom.second-offers-box .text-section h3,.thanku-screen.inserted-thank-screen .modal-box-custom.second-offers-box .text-section h3{
      font-size: 21px!important;
    }
    .thanku-screen .netspend-main-content img {
      max-height: 100px;
  }
  .thanku-screen .congratulation-modal .sw-theme-dots>ul.step-anchor>li>a,.thanku-screen.inserted-thank-screen .congratulation-modal .sw-theme-dots>ul.step-anchor>li>a  {
    font-size: 15px;
  }
  .thanku-screen  .congratulation-modal.last_step_congratulation_modal h6,.thanku-screen  .congratulation-modal.last_step_congratulation_modal span {
    font-size: 16px;
    line-height: 20px;
  }
}

@media only screen and (max-width: 1199px) {
  span.green-big-text {
    font-size: 25px;
    line-height: 30px;
  }
  .serivce-box {
    padding: 4px;
  }
  ul.start-list li {
    padding-right: 3px;
    font-size: 12px;
  }
  .serivce-box p {
    font-size: 12px;
  }
  .service-data h4 {
    font-size: 20px;
    line-height: 35px;
  }
  .deals-logo-wraper {
    height: 48px;
  }
  .service-data h1 {
    font-size: 40px;
    line-height: 64px;
  }
  ul.radio-list.simple-radio-butn {
    margin-top: 4px;
  }
  .courtesy-msg-box {
    max-width: 350px;
  }

  .application-step-form-wraper::after {
    z-index: -1;
  }

  .no-deal-image {
    width: 176px;
    margin-bottom: 20px;
  }

  .lead-step-form-wraper {
    padding: 15px;
  }

  .step1-left-content {
    max-width: 650px;
  }

  .step1-right-content {
    flex: 0 0 425px;
    max-width: 519px;
  }

  .form_content.blue-form-box {
    padding: 20px 25px;
  }

  .application-step-form-wraper::before {
    top: 0;
    height: 176px;
  }

  .step3-leads-card .col-lg-3 {
    padding: 4px;
  }

  .font-18 p {
    font-size: 15px;
  }

  .form_content label {
    font-size: 14px;
  }

  .form_content .form-control,
  .form_content select {
    padding: 10px 10px;
    font-size: 14px;
  }

  .form_content select {
    margin-top: 15px;
    background-position-y: calc(100% - 14px);
  }

  .Submitting_form {
    font-size: 13px;
    line-height: 16px;
  }

  .form_content {
    padding: 50px;
  }

  .terms_condition {
    margin-top: 16px;
  }

  .form_image {
    display: none;
  }

  .qoute__form {
    padding: 0;
    margin: 20px 0px 100px 0px;
  }
  ul.radio-list li {
    margin-left: 10px;
  }
  .simple-radio-butn input[type="radio"] + label {
    padding-left: 22px;
    font-size: 13px;
  }
  .device_with_plan .grid-wrapper {
    grid-gap: 6px;
  }
  .device_with_plan .grid-col-auto {
    grid-gap: 6px;
    place-items: self-start;
    grid-auto-flow: row;
  }
  .deals-step-screen .step3-leads-card_inner {
    max-height: calc(100vh - 295px);
}
.deals-step-screen.wiresless-deals-viewport .step3-leads-card_inner {
  max-height: calc(100vh - 250px);
}
.deals-step-screen .custom-container {
  padding: 0 4%;
}
.d-none-mobile{
  display: none;
}
.deals-custom-wrap-screen .step1-right-content {
  flex: 0 0 500px;
  max-width: 500px;
}
.congratulation-modal.last_step_congratulation_modal button.left-arrow {
  left:10px;
}
.congratulation-modal.last_step_congratulation_modal button.right-arrow {
  right:10px;
}
.grid-wrapper.custom-mobile-grid{
  display: flex;
  flex-wrap: wrap;
  row-gap: 0;
}
.grid-wrapper.custom-mobile-grid label.radio-card .card-content-wrapper{
  width: 131px;
}
.installation-mobile-date .orange-filled-btn {
  font-size: 15px;
  line-height: 20px;
  padding: 9px 12px;
}
}

@media only screen and (max-width: 991px) {
  .service-data h1 {
    font-size: 40px;
    line-height: 43px;
  }
  .device_with_plan .grid-col-auto {
    grid-auto-flow: column;
    align-items: center;
  }
  .deals-logo-wraper img {
    height: auto;
  }
  .device_with_plan .card-content {
    flex-direction: column;
  }
  .service-data {
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .courtesy-msg-box {
    max-width: 399px;
    flex-direction: column;
    align-items: start !important;
  }
  .courtesy-msg-box-wraper {
    align-items: unset !important;
  }
  ul.radio-list li {
    margin-left: 6px;
  }
  .step1-right-content {
    flex: 0 0 320px;
    max-width: 519px;
  }
  .courtesy-msg-box-wraper {
    flex-direction: column;
  }
  .courtesy-msg-box {
    max-width: 100%;
  }
  .pkg-amount-box {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .application-step-form-wraper {
    padding: 18px 0;
    min-height: calc(100vh - 130px);
  }

  .leads-step3-wraper .lead-step-form-card,
  .padding-70 {
    padding: 25px;
  }

  .grey-bg-card {
    max-width: 400px;
  }

  .grey-bg-card-content {
    padding: 10px;
  }

    .right-end-text {
        width: calc(100% - 122px);
    }

    ul.radio-list.simple-radio-butn {
        flex-wrap: wrap;
    }
    .custom-chat-box ul.chat-messages li {
        padding: 9px;
        font-size: 15px;
    }
  .right-end-text {
    width: calc(100% - 122px);
  }

  ul.radio-list.simple-radio-butn {
    flex-wrap: wrap;
  }
  .deals-step-screen .leads-step3-wraper .card-body {
    padding: 7px 10px 20px;
  }
  .congratulation-modal .sw-theme-dots>ul.step-anchor>li>a{
    padding-top: 30px;
  }
  .congratulation-modal p{
    margin-bottom: 4px;
  }
  .congratulation-modal.last_step_congratulation_modal button.left-arrow {
    left:-14px;
  }
  .congratulation-modal.last_step_congratulation_modal button.right-arrow {
    right:-14px;
  }
  .congratulation-modal.last_step_congratulation_modal .item_cong img {
    object-fit: cover;
  }
  /* .congratulation-modal.last_step_congratulation_modal h6 {

    font-size: 32px;
  } */
  .modal-box-custom.second-offers-box .text-section h3 {
    font-size: 28px;
  }
.netspend-main-content img{
  max-height: 150px;
}
.congratulation-modal.last_step_congratulation_modal .custom-out-of-box-header h6 {
  font-size: 30px;
}
.payment-detail-modal h5 {
  font-size: 16px;
}
.paymeny-deatail-header h4 {
  font-size: 17px;
}
/* .congratulation-modal.last_step_congratulation_modal h6 {
  font-size: 30px;
} */
/* .thanks-offers-wraper {
  height: 100vh!important;
} */
/* .thanku-screen .modal-box-custom {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  max-width: 600px!important;
} */
.thanku-screen .congratulation-modal.last_step_congratulation_modal button.left-arrow {
  left: 51px;
}
.thanku-screen .congratulation-modal.last_step_congratulation_modal button.right-arrow {
  right: 46px;
}
.thanku-screen.inserted-thank-screen .congratulation-modal.last_step_congratulation_modal button.left-arrow {
  left: -29px;
}
.thanku-screen.inserted-thank-screen .congratulation-modal.last_step_congratulation_modal button.right-arrow {
  right: -29px;
}
.thanku-screen.inserted-thank-screen .text-btn-wrap button{
  min-width: 100%;
}
.thanku-screen.inserted-thank-screen .text-btn-wrap button:nth-child(1){
  margin-bottom: 10px;
}

.thanku-screen.inserted-thank-screen .text-btn-wrap button {
  height: 37px;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}
ul.leads-footer-page.updated-footer {
  grid-template-columns: repeat(3, 1fr);
}

}

@media only screen and (max-width: 767px) {
  ul.start-list li {
    font-size: 16px;
  }
  button.link---btn {
    border: none;
    background: transparent;
    color: #555;
    margin-right: 20px;
  }
  button.link---btn > span {
    margin-right: 5px;
    font-size: 14px;
  }
  .leads-step3-wraper .card-header h6 {
    font-size: 25px;
    width: 100%;
    text-align: center;
  }
  .leads-step3-wraper .card-header {
    padding: 15px;
  }
  label.radio-card
    .card-content-wrapper.card-services-content-wrapper
    .small-device-wraper
    img {
    width: 25px;
    height: 25px;
  }
  label.radio-card .card-content-wrapper.card-services-content-wrapper {
    width: 50px;
    height: 50px;
  }
  .step3-leads-card_inner
    .step3-select-plan
    .selct-plan-check-box
    .overflow-control-description {
    width: 100%;
    width: 420px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .step3-select-plan
    .selct-plan-check-box
    .custom-control.overflow-checkbox
    .overflow-control-indicator {
    border: 2px solid #ffffff;
    left: 38%;
    z-index: 9;
  }
  .step3-select-plan
    .selct-plan-check-box
    .overflow-control-description::before {
    width: 100%;
  }
  .desktop-viewdeals {
    display: none;
  }

  .mobile-viewdeals {
    display: block;
  }
  .no-deal-found {
    min-height: calc(100vh - 579px);
  }
  .no-deal-image {
    width: 116px;
  }
  .courtesy-text {
    justify-content: center;
  }
  .courtesy-msg-box {
    max-width: 100%;
  }
  .courtesy-msg-box .grid-wrapper {
    margin-right: 5px;
    margin: unset;
    display: flex;
    justify-content: start;
    text-align: start;
  }
  .service-data {
    align-items: start;
    display: flex;
    min-height: 100px;
  }
  .courtesy-msg-box .fency-check-radio-button {
    width: 100%;
  }
  .courtesy-msg-box p {
    text-align: center;
  }
  /* .courtesy-msg-box .fency-check-radio-button{display: flex;} */
  .courtesy-msg-box .grid-wrapper {
    margin-right: 5px;
  }
  .courtesy-msg-box-wraper {
    justify-content: center;
  }
  ul.radio-list.simple-radio-butn {
    text-align: center;
    justify-content: start;
  }

  ul.radio-list li {
    margin-bottom: 10px;
  }

  label.radio-card .card-content-wrapper {
    min-height: 80px;
  }

  .terms-text {
    position: absolute;
    top: 54px;
  }

  .step1-right-content {
    flex: 0 0 100%;
    margin-left: unset;
    margin-top: -31%;
    width: 100%;
  }

  .step1-left-content .terms-text a,
  .step1-left-content .terms-text a span {
    color: #000 !important;
  }

  .application-step1-bottom-content {
    flex-direction: column;
  }

  .serivce-box {
    text-align: center;
  }

  .packg-detail-row .col-sm-4 {
    padding: 5px;
  }

  .order-submitted-inner {
    max-width: 100%;
    text-align: center;
  }

  .grey-bg-card {
    margin: 0 auto;
  }

  .form_content {
    padding: 25px;
  }

  ul.radio-list.simple-radio-butn {
    margin-top: 0;
  }

  ul.start-list {
    margin: 0 auto;
  }

  ul.start-list li {
    padding-right: 4px;
  }

  .packg-detail-row .serivce-box p {
    font-size: 15px;
  }

    .packg-detail-row .serivce-box {
        padding: 5px;
    }
    .service-data h1 {
        font-size: 74px;
        letter-spacing: 1px;
    }
    .service-data {
        padding: 22px;
    }
    .font-bold {
        font-weight: 700;
    }
    span.green-big-text {
        font-size: 41px;
        line-height: 44px;
    }
    ul.radio-list.simple-radio-butn.step3-select-plan li {
        margin-left: 0;
    }
    .device_with_plan .card-content {
        flex-direction: row;
    }
    .courtesy-msg-box.device_with_plan{
        margin-top: 10px;
    }
    .courtesy-msg-box.device_with_plan .fency-check-radio-button{
        display: flex;
        align-items: center;
    }
    .courtesy-msg-box.device_with_plan .grid-wrapper{
        margin-right: 10px;
    }
    .courtesy-msg-box.device_with_plan label.radio-card input[type=radio]{
        position: absolute;
    }
    .step3-leads-card_inner .courtesy-msg-box img{
        vertical-align: baseline;
    }
    .device_with_plan + div ul.radio-list.simple-radio-butn{
        justify-content: space-between;
    }
    .step3-leads-card_inner .selct-plan-check-box .overflow-control-description{
        width: 100%;
    }
    .step3-leads-card_inner .selct-plan-check-box .overflow-control-description .orange-clr{
        text-align: left;
    }
    .simple-radio-butn label {
        font-size: 14px;
    }
    .device_with_plan .card-content small {
        font-size: .775em;
        text-align: left;
        color: #6e6a6a !important;
    }
    .serivce-box {
        border: none;
        text-align: start;
    }
    .deals-logo-wraper {
        height: 67px;
        width: 160px;
        margin-bottom: 4px;
    }
    .deals-logo-wraper img{
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    .serivce-box p {
        font-size: 13px;
    }
    .deals-logo-wraper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
    }
    .deals-logo-wraper img{
        height: auto;
    }
    ul.radio-list li {
        margin-bottom: 17px;
    }
    .tawk-min-container .tawk-button-circle.tawk-button-large {
        width: 50px;
        height: 50px;
    }
    .step3-leads-card_inner {
        max-height: calc(100vh - 325px);
    }
    .chat-invoice-screen  .grey-bg-card{
        max-width: 100%;
    }
  .packg-detail-row .serivce-box {
    padding: 5px;
  }
  .service-data h1 {
    font-size: 74px;
    letter-spacing: 1px;
  }
  .service-data {
    padding: 22px;
  }
  .font-bold {
    font-weight: 700;
  }
  span.green-big-text {
    font-size: 41px;
    line-height: 44px;
  }
  ul.radio-list.simple-radio-butn.step3-select-plan li {
    margin-left: 0;
  }
  .device_with_plan .card-content {
    flex-direction: row;
  }
  .courtesy-msg-box.device_with_plan {
    margin-top: 10px;
  }
  .courtesy-msg-box.device_with_plan .fency-check-radio-button {
    display: flex;
    align-items: center;
  }
  .courtesy-msg-box.device_with_plan .grid-wrapper {
    margin-right: 10px;
  }
  .courtesy-msg-box.device_with_plan label.radio-card input[type="radio"] {
    position: absolute;
  }
  .step3-leads-card_inner .courtesy-msg-box img {
    vertical-align: baseline;
  }
  .device_with_plan + div ul.radio-list.simple-radio-butn {
    justify-content: space-between;
  }
  .step3-leads-card_inner .selct-plan-check-box .overflow-control-description {
    width: 100%;
  }
  .step3-leads-card_inner
    .selct-plan-check-box
    .overflow-control-description
    .orange-clr {
    text-align: left;
  }
  .simple-radio-butn label {
    font-size: 14px;
  }
  .device_with_plan .card-content small {
    font-size: 0.775em;
    text-align: left;
    color: #6e6a6a !important;
  }
  .serivce-box {
    border: none;
    text-align: start;
  }
  .deals-logo-wraper {
    height: 67px;
    width: 160px;
    margin-bottom: 4px;
  }
  .deals-logo-wraper img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .serivce-box p {
    font-size: 13px;
  }
  .deals-logo-wraper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .deals-logo-wraper img {
    height: auto;
  }
  ul.radio-list li {
    margin-bottom: 17px;
  }
  .tawk-min-container .tawk-button-circle.tawk-button-large {
    width: 50px;
    height: 50px;
  }
  .step3-leads-card_inner {
    max-height: calc(100vh - 325px);
  }
  .congratulation-modal h2 {
    font-size: 28px;
    line-height: 23px;
  }
  /* .congratulation-modal.last_step_congratulation_modal h6 {

    font-size: 28px;
  } */
  .last_step_congratulation_modal .congratulation-modal-header {
    margin-top: 70px;
  }
  .congratulation-modal.last_step_congratulation_modal .modal-content {
    padding: 20px 22px 20px;
  }
  .custom-out-of-box-header {
    padding: 22px 13px;
    /* top: -8px; */
  }
  .last_step_congratulation_modal .congratulation-modal-header {
    margin-top: 94px;
  }
  .congratulation-modal.last_step_congratulation_modal .custom-out-of-box-header h6 {
    font-size: 25px;
  }
  .thanks-offers-wraper {
    margin: 0 30px;
  }
  .thanku-screen .modal-box-custom {
    max-width: 450px !important;
}
.thanku-screen .congratulation-modal.last_step_congratulation_modal button.right-arrow {
  right: 0px;
}
.thanku-screen .congratulation-modal.last_step_congratulation_modal button.left-arrow {
  left: 0px;
}
 .installation-mobile-date{
  text-align: left!important;
  }
}

@media only screen and (max-width: 575px) {
  .font-bold {
    font-weight: 600;
  }
  .service-top-price {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .fency-rounded-radio-button .tabs-radio-btns label.radio-card {
    line-height: 5px;
  }
  .tabs-radio-btns {
    margin-top: -22px;
  }
  .tabs-radio-btns
    label.radio-card
    .card-content-wrapper.card-services-content-wrapper {
    width: 40px;
    height: 40px;
    margin: 0 auto 7px;
    margin-bottom: 10px;
  }
  .leads-step3-wraper .card-header {
    padding: 7px;
  }
  .leads-step3-wraper .card-header h6 {
    font-size: 18px;
  }
  .step3-leads-card_inner .card-body {
    padding: 20px;
  }
  .step3-leads-card_inner
    .step3-select-plan
    .selct-plan-check-box
    .overflow-control-description {
    width: 392px;
  }
  .service-data {
    min-height: 60px;
  }
  small.courtesy-msg-box {
    font-size: 12px;
    max-width: 160px;
  }
  .deals-logo-wraper {
    margin-bottom: 10px;
  }
  .deals-logo-wraper {
    height: 40px;
    width: 158px;
    margin-bottom: 5px;
  }
  .deals-logo-wraper img {
    height: 100%;
  }
  ul.radio-list li {
    margin-bottom: 5px;
  }
  label.radio-card .card-content-wrapper {
    width: 125px;
  }
  span.green-big-text {
    font-size: 28px;
    line-height: 32px;
  }

  .toggle-heading p {
    padding-bottom: 5px;
  }

  label.radio-card .card-content-wrapper {
    min-height: 100px;
  }

  .grid-wrapper {
    grid-gap: 10px;
  }
  .step1-left-content .terms-text {
    display: none;
  }
  .simple-radio-butn label {
    font-size: 15px;
  }

  .simple-radio-butn [type="radio"]:checked + label,
  .simple-radio-butn [type="radio"]:not(:checked) + label {
    padding-left: 23px;
    line-height: 18px;
  }

  .service-data h4 {
    font-size: 20px;
    line-height: 38px;
  }

  .right-end-text {
    width: calc(100% - 96px);
  }

  .leads-step3-wraper .lead-step-form-card,
  .padding-70 {
    padding: 10px 9px;
  }

  h5.contact-text {
    font-size: 20px;
  }

  .form_content {
    padding: 25px 10px;
  }

  .form_content .form-control,
  .form_content select,
  .form_content label {
    font-size: 12px;
  }

  .electronic-signature-wraper {
    flex-direction: column;
  }

  .electronic-signature {
    margin-top: 10px;
    text-align: center;
  }

  .agreement-signature {
    margin: 0 auto;
  }

  .lead-step-form-card {
    padding: 16px 6px;
  }

  .lead-step-form-card h6 {
    font-size: 17px;
    line-height: 20px;
  }

  .lead-step-form-top-heading-content {
    margin: 0 auto 15px;
  }
  .lead-step-form-card p {
    font-size: 13px;
  }
  .device_with_plan label.radio-card .card-content img {
    width: 35px;
    height: 35px;
  }
  .device_with_plan .card-content small {
    font-size: 10px;
  }
  .step3-leads-card_inner {
    max-height: calc(100vh - 286px);
  }
  .selct-plan-check-box .overflow-control-description::before {
    top: -10px;
    height: 44px;
  }
  .small-device-wraper img {
    height: 40px;
  }
  .packg-detail-row .serivce-box p {
    font-size: 13px;
  }
  .congratulation-modal .modal-dialog {
    margin: 0;
  }
  .congratulation-modal .modal-content {
    padding: 20px 25px 20px;
  }
  .congratulation-modal h6 {
    font-size: 23px;
    line-height: 26px;
    margin-top: 20px;
  }
  .deals-custom-wrap-screen .for-red {
    font-size: 13px;
  }
  .text-btn-wrap button:nth-child(1) {
    /* margin-right: 0; */
    margin-bottom: 20px;
    font-size: 17px;
    max-width: 100%;
  }
  .text-btn-wrap button:nth-child(2) {
    max-width: 100%;
    font-size: 17px;
  }
  .modal-box-custom.second-offers-box .text-section h3 {
    font-size: 23px;
    line-height: 30px;
  }
  .last_step_congratulation_modal p.learn-more {
    font-size: 20px;
 
  }
  .congratulation-modal.last_step_congratulation_modal .item_cong img {
    height: 119px;
  }
  .modal-box-custom {
    min-height: unset;
    height: auto;
    margin-bottom: 0;
  }
  .last_step_congratulation_modal .congratulation-modal-header {
    margin-top: 110px;
  }
  span.theme-color-green {
    font-size: 24px;
  }
  .modal-box-custom .text-section h6 {
    font-size: 21px;
  }
  .congratulation-modal.last_step_congratulation_modal .f_21{
    font-size: 18px;
  }
  .congratulation-modal.last_step_congratulation_modal button.left-arrow, .congratulation-modal.last_step_congratulation_modal button.right-arrow {
    top: 65%;
}
.congratulation-modal.last_step_congratulation_modal .congratulation-modal-header h2 {
  font-size: 24px;
}
.payment-detail-modal .stripe_field_wrap {
  padding: 12px;
}
/* .thanku-screen .congratulation-modal.last_step_congratulation_modal button.left-arrow {
  display: none;
}
.thanku-screen .congratulation-modal.last_step_congratulation_modal button.right-arrow {
  display: none;
} */
.thanku-screen .modal-box-custom {
  max-width: 360px !important;
}
.thanku-screen .congratulation-modal .sw-theme-dots>ul.step-anchor>li>a {
  padding: 0 0px 0 9px;
  font-size: 14px;
  font-weight: 500;
}
.thanku-screen .congratulation-modal.last_step_congratulation_modal .sw-theme-dots>ul.step-anchor>li.active>a:after {
  bottom: -10px;
}
.thanku-screen .congratulation-modal.last_step_congratulation_modal .sw-theme-dots>ul.step-anchor>li>a:after {
  bottom: -11px;
}
.thanku-screen.inserted-thank-screen .congratulation-modal.last_step_congratulation_modal button.left-arrow {
  left: -26px;
}
.thanku-screen.inserted-thank-screen .congratulation-modal.last_step_congratulation_modal button.right-arrow {
  right: -26px;
}
.thanku-screen.inserted-thank-screen .congratulation-modal.last_step_congratulation_modal h2 {
  font-size: 20px;
  margin-bottom: 15px;
  line-height: 23px;
}
.thanku-screen.inserted-thank-screen .congratulation-modal .sw-theme-dots>ul.step-anchor>li>a {
  font-size: 13px;
}
.thanku-screen.inserted-thank-screen .netspend-visa-wrapper .netspend-main-content p {
  font-size: 14px;
  margin: 9px 0;
}
.thanku-screen .netspend-main-content img {
  max-height: 75px;
}
ul.leads-footer-page.updated-footer {
  grid-template-columns: repeat(2, 1fr);
}
}
@media only screen and (max-width: 480px) {
  .service-data h1 {
    font-size: 60px;
  }
  .service-data {
    min-height: 50px;
    padding: 15px;
  }
  .step3-leads-card_inner
    .step3-select-plan
    .selct-plan-check-box
    .overflow-control-description {
    width: 320px;
  }
  .step3-select-plan
    .selct-plan-check-box
    .custom-control.overflow-checkbox
    .overflow-control-indicator {
    left: 35%;
  }
.congratulation-modal {
    padding-left: 0!important;
  }
  .congratulation-modal p {
    margin: 0 auto 8px;
  }
  .congratulation-modal h6 {
    font-size: 19px;
  }
  .congratulation-modal-header {
    padding: 0px;
  }
 

  .congratulation-modal h2 {
    font-size: 23px;
    line-height: 14px;
  }
  .congratulation-modal .modal-header {
    padding: 23px 4px;
  }
  .congratulation-modal .modal-content {
    padding: 23px 12px 20px;
  }
  .deals-custom-wrap-screen .form_content.blue-form-box {
    padding: 20px 20px;
  }
  .form_content.blue-form-box .form-control.appear_none {
    background-position-y: calc(100% - 17px);
    background-position-x: calc(100% - 5px);
  }
  .deals-custom-wrap-screen .for-red {
    font-size: 12px;
  }
  .deals-custom-wrap-screen .form_content.blue-form-box .form-control {
    padding: 10px 5px;
    background-position-x: calc(100% - 3px);
    background-size: 13px;
  }
  .custom-out-of-box-header {
    padding: 15px 10px;
  }
  .congratulation-modal.last_step_congratulation_modal h6 {
    font-size: 21px;
    line-height: 34px;
}
.last_step_congratulation_modal h5 {
  font-size: 18px;
  margin-bottom: 5px;
}
.congratulation-modal.last_step_congratulation_modal .modal-content {
  margin: 180px auto 40px;
}
.custom-out-of-box-header {
  top: -167px;
}
.last_step_congratulation_modal .congratulation-modal-header h2{
  margin-top: 0px;
  font-size: 25px;
  padding-bottom: 0;

}
.last_step_congratulation_modal .congratulation-modal-header{
  margin-top: 10px;
}
.congratulation-modal.last_step_congratulation_modal .modal-header {
  padding-bottom: 0;
}
.congratulation-modal.last_step_congratulation_modal .modal-content {
  padding: 20px 10px 20px;
}
.congratulation-modal.last_step_congratulation_modal button.left-arrow,.congratulation-modal.last_step_congratulation_modal button.right-arrow {
  top: 70%;
}
.last_step_congratulation_modal .congratulation-modal-header p {
  font-size: 17px;
}
.netspend-main-content img {
  max-height: 106px;
}
.congratulation-modal.last_step_congratulation_modal .btn-close {
  top: -166px;
}
.fs-17 {
  font-size: 14px;
}
.thanku-screen .custom-out-of-box-header {
  top: 0;
}
.thanku-screen .congratulation-modal .sw-theme-dots ul {
  margin-top: 70px;
  row-gap: 58px;

}
.thanks-offers-wraper {
  margin: 0 0px;
}
.thanku-screen .congratulation-modal .sw-theme-dots>ul.step-anchor>li>a {
  padding-top: 0;
}
.thanku-screen .congratulation-modal.last_step_congratulation_modal button.right-arrow {
  right: 11px;
  border: 5px solid #052c65;
  border-right: 0;
  border-top: 0;

  /* display: none; */
}
.thanku-screen .congratulation-modal.last_step_congratulation_modal button.left-arrow {
  left: 11px;
  border: 5px solid #052c65;
  border-right: 0;
  border-top: 0;
  /* display: none; */
}
.thanku-screen {
  margin-bottom: 20px;
}
.thanku-screen .congratulation-modal.last_step_congratulation_modal .item_cong img {
  height: 150px!important;
}
.thanku-screen .congratulation-modal.last_step_congratulation_modal button.left-arrow,.thanku-screen  .congratulation-modal.last_step_congratulation_modal button.right-arrow {
  top: 80%;
}
.thanku-screen .congratulation-modal.last_step_congratulation_modal h2 {
  font-size: 22px;
}
.thanku-screen .congratulation-modal .sw-theme-dots ul {
  margin-top: 42px;
}
  .thanku-screen .modal-box-custom {
      max-width: 345px !important;
  }
  .thanku-screen .modal-box-custom.second-offers-box .text-section h3 {
    font-size: 23px!important;
  }
  .thanku-screen .congratulation-modal .sw-theme-dots>ul.step-anchor>li>a {
    padding: 0 0px 0 9px;
    font-size: 14px;
    font-weight: 500;
  }
  .thanku-screen .congratulation-modal.last_step_congratulation_modal .sw-theme-dots>ul.step-anchor>li>a:after {
    left: 45%;
    bottom: -10px;

  }
  .thanku-screen .last_step_congratulation_modal .congratulation-modal-header {
    margin-top: 13px!important;
  }
  .thanku-screen .congratulation-modal.last_step_congratulation_modal h2 {
    margin-left: 8px;
    margin-right: 8px;
  }
  .thanku-screen .congratulation-modal.last_step_congratulation_modal .custom-out-of-box-header h6 {
    font-size: 18px;
  }
  .mobiled-block{
    display: block;
  }
  .thanku-screen .congratulation-modal.last_step_congratulation_modal button.right-arrow, .thanku-screen .congratulation-modal.last_step_congratulation_modal button.left-arrow {
    top: 69%!important;
}
.thanku-screen.inserted-thank-screen .congratulation-modal .modal-box-custom.second-offers-box .text-section h3 {
  font-size: 19px !important;
}
.inserted-second-view  label {
  font-size: 13px;
}
.thanku-screen.inserted-thank-screen .congratulation-modal .sw-theme-dots ul {
  margin-top: 32px;
}
.inserted-order-2{
  order: 2;
}
.inserted-display-block{
  display: block;
  text-align: center;
}
.inserted-display-none{
  display: none;
}
.textual-display-none{
  display: block;
}
.textual-display-block{
  display: none;
}
.thanku-screen .last_step_congratulation_modal p.learn-more {
  font-size: 15px;
  margin: 0;
}

.thanku-screen  .congratulation-modal.last_step_congratulation_modal h6,.thanku-screen  .congratulation-modal.last_step_congratulation_modal span {
  font-size: 15px;
  line-height: 24px;
}

}

@media only screen and (max-width: 400px) {
  .packg-detail-row .serivce-box p {
    font-size: 12px;
  }
  .step3-select-plan
    .selct-plan-check-box
    .custom-control.overflow-checkbox
    .overflow-control-indicator {
    left: 31%;
  }
  .deals-logo-wraper {
    width: 180px;
  }
  .device_with_plan .card-content {
    flex-direction: column;
    text-align: center;
  }
  .device_with_plan .card-content small {
    text-align: center;
  }
  .step3-leads-card_inner
    .step3-select-plan
    .selct-plan-check-box
    .overflow-control-description {
    width: 280px;
  }
  ul.radio-list.simple-radio-butn {
    flex-wrap: wrap;
  }

  .service-data h1 {
    font-size: 50px;
  }

  .packges-name-row .col {
    padding: 3px;
  }

  .application-step1-top-content h3 {
    font-size: 27px;
    line-height: 30px;
  }
  .service-data {
    padding: 6px;
  }

  .service-data p {
    line-height: 12px;
    font-size: 11px;
  }

  .service-data h4 {
    font-size: 32px;
    line-height: 42px;
  }

  .simple-radio-butn label {
    font-size: 12px;
  }
  .deals-custom-wrap-screen .form_content.blue-form-box .form-control::placeholder,.deals-custom-wrap-screen .form_content.blue-form-box .form-control{
    font-size: 13px;
  }
  .thanku-screen .modal-box-custom {
    max-width: 335px !important;
}
}

.hide_btn {
  display: none !important;
}
.flex-width {
  flex: 0 0 100%;
}
/* .thanku-screen {
  position: relative;
  top: 25px;
} */
.thanks-offers-wraper{
  margin-top: 0px;
  height: auto;
  position: relative;
}
.thanku-screen .congratulation-modal.last_step_congratulation_modal .item_cong img {
  max-width: 100%;
  height: 231px;
  width: 100%;
  object-fit: contain;
}
.thanku-screen .modal-box-custom.second-offers-box .text-section h3 {
  font-size: 27px;
  line-height: 32px;
}
.thanku-screen .text-btn-wrap {
  margin: 13px auto 13px auto;
}
.thanku-screen .modal-box-custom {
  /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */
  max-width: 500px;
}
.thanku-screen .custom-out-of-box-header{
  position: unset;
  margin-top: 0;
}
.thanku-screen .congratulation-modal.last_step_congratulation_modal h2{
  margin-bottom: 27px;
}
.thanku-screen .last_step_congratulation_modal .congratulation-modal-header{
  margin-top: 0;
  padding: 0;
  padding-bottom: 12px;
}
.thanku-screen .congratulation-modal.last_step_congratulation_modal button.right-arrow,.thanku-screen .congratulation-modal.last_step_congratulation_modal button.left-arrow {
  top: 61%;
  cursor: pointer;
}