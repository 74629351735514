.chat-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .chat-button:hover {
    background-color: #0056b3;
    transform: scale(1.1);
  }
  