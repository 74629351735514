.baner-section {
  /* background-image: url(../../../../assets/images/banner-bg.png); */
  /* min-height: 100vh; */
  /* min-height: 83vh; */
  min-height: unset;
  background-repeat: no-repeat;
  background-position-y: -61px;
  background-position-x: right;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 50px;
}

.baner-section::before {
  background-image: url(../../../../assets/images/baner-left-curve.png);
  background-repeat: no-repeat;
  top: 140px;
  width: 100px;
  height: 490px;
  left: 0;
  position: absolute;
  content: "";
}

.baner-section::after {
  position: absolute;
  content: "";
  background-image: url(../../../../assets/images/baner-right-curve.png);
  background-repeat: no-repeat;
  width: 216px;
  height: 500px;
  bottom: -143px;
  right: 0;
}

.for-red {
  color: #d9534f; /* Red color for error text */
  font-size: 0.875rem; /* Slightly smaller font size for error message */
  font-weight: 500; /* Medium weight for emphasis */
  margin-top: 4px; /* Space between input field and error message */
  display: block; /* Ensures the error is displayed as a block below the input */
}

.baner-content {
  max-width: 740px;
  /* padding-top: 130px; */
}

.baner-btn {
  margin-top: 70px;
}

.baner-content p {
  font-size: 28px;
  line-height: 30px;
  padding-top: 20px;
  font-weight: 300;
}
.field-set {
  width: 50%;
}
.form_content_banner {
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 14px #00000029;
  border-radius: 10px;
  padding: 18px 30px;
  margin-top: 25px;
  color: #1a2a79;
  text-transform: capitalize;
  font-size: 20px;
}
.form_content_banner  label{font-size: 16px;}
@media only screen and (max-width: 1600px) {
  .baner-content p {
    font-size: 20px;
    padding-top: 16px;
  }
  .baner-btn {
    margin-top: 50px;
  }

  .baner-section::after {
    width: 180px;
    height: 462px;
    bottom: -134px;
  }

  .baner-section::before {
    top: -95px;
    left: -37px;
  }
  .baner-section h2{
    line-height: 90px;
  }
}

@media only screen and (max-width: 1399px) {
  .form_content_banner  label {
    font-size: 14px;
}
  .baner-section::after {
    width: 128px;
  }

  .baner-section::before {
    top: -150px;
    left: -50px;
  }

  .baner-btn {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 1199px) {
  .baner-btn {
    margin-top: 30px;
  }
  .baner-section {
    /* min-height: 78vh; */
    background-position-x: 360px;
  }
}

@media only screen and (max-width: 992px) {
  .baner-content p {
    font-size: 16px;
    padding-top: 8px;
  }
  .baner-section {
    min-height: 56vh;
  }
  .baner-content{
    padding-top: 0;
    max-width: 100%;
    text-align: center;
  }
  .baner-section h2 {
    line-height: 47px;
  }
  .baner-content{
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .baner-section {
    /* background-position-x: 337px; */
    /* min-height: 60vh; */
  }
  .baner-section::after {
    width: 116px;
    right: -12px;
  }
}
@media only screen and (max-width: 767px) {
  .baner-section {
    background-position-x: 0px;
    /* min-height: 100vh; */
  }

  .baner-content p {
    font-size: 14px;
    padding-top: 8px;
    max-width: 100%;
  }
  .baner-content {
    /* margin: 0 auto; */
    text-align: center;
    /* padding-top: 430px; */
  }
  .theme-btn {
    max-width: 370px;
    padding-top: 200px;
    margin: 0 auto;
    text-align: center;
  }

  .baner-section::after {
    display: none;
  }
  .baner-section {
    background-position-x: 0px;
    /* min-height: 100vh; */
    background-position: top right;
    background-size: 137% 66%;
  }
  /* .lead-providers {
    margin-top: -80px;
  } */
}
@media only screen and (max-width: 675px) {
  .baner-content p {
    font-size: 14px;
    line-height: 20px;
  }
  .form_content_banner {
    padding: 15px;
  }
  .baner-content h2 {
    font-size: 23px;
    line-height: 27px;
  }
  .baner-content h1 {
    font-size: 24px;
    line-height: 35px;
  }
  .baner-btn {
    margin-top: 20px;
  }
  /* .baner-content {
    padding-top: 330px;
  } */
  .baner-section {
    background-size: 100% 68%;
    min-height: 100%;
  }
  .lead-providers {
    margin-top: 0;
  }
}
@media only screen and (max-width: 500px) {
  .baner-section::before {
    display: none;
  }
  .baner-section::after {
    width: 58px;
    right: -1px;
  }
  .lead-providers {
    margin-top: 0;
  }
  .baner-content h1 {
    font-size: 18px;
    line-height: 30px;
  }
  .baner-content h2 {
    font-size: 20px;
    line-height: 23px;
  }
  /* .baner-content {
    padding-top: 330px;
  } */
  .baner-section {
    background-size: 100% 68%;
    min-height: 100%;
  }
}
@media only screen and (max-width: 420px) {
  .baner-content p {
    max-width: 100%;
  }
  .baner-btn .theme-btn {
    height: 43px;
    width: 100%;
    padding: 7px 15px;
    font-size: 20px;
  }
}
