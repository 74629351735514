/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
/* .tooltip:hover .tooltiptext {
  visibility: visible;
} */
.tooltip-wrapper {
  position: relative;
  text-transform: initial;
}
.main-wrapper-tooltip {
  margin-top: 0;
}
.main-wrapper-tooltip p {
  text-transform: initial;
}
.tooltips:hover span.tooltiptext {
  display: block;
  filter: alpha(opacity=100);
  top: -180px;
  left: 0px;
  z-index: 99;
}

.tooltips {
  color: #1e368a;
  text-decoration: underline;
  margin-left: 4px;
}

.tooltip-wrapper span.tooltiptext {
  background: none repeat scroll 0 0 #ecf4eb;
  color: black;
  font-family: "Helvetica";
  font-size: 0.8em;
  font-weight: normal;
  line-height: 1.5em;
  padding: 16px 15px;
  width: 347px;
  top: -594px;
  left: 0px;
  margin-left: 0;
  display: none;
  filter: alpha(opacity=0);
  position: absolute;
  text-align: center;
  z-index: 50;
  text-transform: none;
  transition: all 0.3s ease-in-out;
  border-radius: 10px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.tooltip-wrapper span.tooltiptext:after {
  content: "";
  background: #ecf4eb;
  left: 21px;
  bottom: -40px;
  position: absolute;
  width: 40px;
  height: 40px;
  clip-path: polygon(0 0, 100% 0, 51% 56%);
}
.tooltip-wrapper > span.tooltips:hover {
  color: #1e368a;
}
.tooltip-wrapper:hover span.tooltiptext {
  opacity: 1;
  display: block;
}
.ml_9 {
  color: #1a2a79;
}
.theme-btn:hover span.ml_9 {
  color: #fff;
}

.spinner {
  border: 4px solid #1a2a79;
  border-left-color: #fff;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  animation: spin 1s infinite linear;
  margin-top: 5px;
  margin-left: 9px;
}
.theme-btn:hover .spinner {
  border: 4px solid #fff;
  border-left-color: gray;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@media (max-width:1800px){
  .tooltip-wrapper span.tooltiptext {
    top: -497px;
  }

}
@media (max-width: 1600px) {
  .tooltips:hover .tooltiptext {
    top: -165px;
  }
  .tooltips .tooltiptext:after {
    bottom: -38px;
  }
  /* .tooltip-wrapper span.tooltiptext {
    top: -206px;
  } */
}
@media (max-width: 1500px) {
  .tooltip-wrapper .tooltiptext {
    max-width: 358px;
  }
  .tooltips:hover .tooltiptext {
    top: -180px;
  }
  .tooltip-wrapper span.tooltiptext:after {
    bottom: -37px;
    left: 40px;
  }
  .tooltip-wrapper span.tooltiptext {
    top: -431px;
    left: -45px;
  }
}
@media (max-width: 1199px) {
  .tooltip-wrapper .tooltiptext {
    top: -185px;
  }
  .tooltip-wrapper .tooltiptext {
    max-width: 300px;
  }
  .tooltips:hover .tooltiptext {
    top: -180px;
  }
  .tooltip-wrapper span.tooltiptext {
    left: -87px;
  }
  .tooltip-wrapper span.tooltiptext:after {
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
@media (max-width: 575px) {
  .tooltips:hover .tooltiptext {
    top: -191px;
    left: 0;
  }
  .tooltip-wrapper .tooltiptext {
    width: 295px;
  }
}
@media (max-width: 480px) {
  .tooltip-wrapper span.tooltiptext {
    top: -450px;
    width: 286px;
    left: -100px;
  }
}
@media (max-width: 380px) {
  .tooltip-wrapper span.tooltiptext {
    left: -111px;
  }
}
