@keyframes pulse_animation {
    0% {
      -webkit-transform: rotateX(0);
      transform: rotateX(0);
      transform: scale(0.5);
    }
    50% {
      transform: scale(0.8);
    }
    100% {
      -webkit-transform: rotateX(-360deg);
      transform: rotateX(-360deg);
      transform: scale(0.5);
    }
  }
  
  #loader-wrapper {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    background: #c1c1c1f5;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    top: 0;
  }
  
  #loader-wrapper .pulse img {
    animation: pulse_animation 1.3s infinite ease-in-out;
  }
  
  #loader-wrapper .pulse span:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .pulse {
    position: relative;
  }
  
  .pulse > img {
    position: absolute;
    width: 316px;
    top: -108px;
    left: -150px;
    z-index: 999;
  }