.site-header {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 16px #00000029;
  width: 100%;
  position: relative;
  z-index: 1;
}

.site-header .navbar {
  padding: 10px 0;
}

.site-header .navbar .pages-names a,
.pages-names.mobile-nav a {
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0px;
  font-weight: 600;
  color: #1a2a79;
  text-transform: capitalize;
  transition: all 0.5s;
  position: relative;
  margin: 0 0 0 50px;
  padding: 0;
  text-decoration: none;
}

.side-bar-menu.offcanvas {
  background: #19277B;
  right: 0;
  width: 250px !important;
}

.offcanvas-header {
  justify-content: end !important;
}

.offcanvas.offcanvas-start {
  transform: translateX(100%) !important;
  left: unset !important;
  right: 0 !important;
  -webkit-transform: translateX(100%) !important;
  -moz-transform: translateX(100%) !important;
  -ms-transform: translateX(100%) !important;
  -o-transform: translateX(100%) !important;
}

.offcanvas.show:not(.hiding),
.offcanvas.showing {
  transform: none !important;
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -ms-transform: none !important;
  -o-transform: none !important;
}

.side-bar-menu .btn-close {
  background-image: url(../../../assets/images/close.svg) !important;
  opacity: 1;
}

.pages-names.mobile-nav a {
  color: #fff;
  margin-bottom: 30px;
  margin-left: 0;
  padding: 12px;
}

.site-main-logo {
  width: 220px;
}

.call-lottie {
  width: 45px;
}

.haeder-phone {
  margin-left: 50px;
}

.site-header .navbar .pages-names a.active,
.site-header .navbar .pages-names a:hover {
  color: #439037 !important;
}

.site-header .navbar .pages-names a:after {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: -4px;
  background: #439037;
  -o-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -webkit-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.site-header .navbar a:hover::after,
.site-header .navbar a.active::after {
  width: 100% !important;
}

.mobile-menu-bar {
  display: none;
}

/* media quries */

@media only screen and (max-width: 1600px) {
  .site-header .navbar .pages-names a {
    margin: 0 0 0 30px;
    font-size: 16px;
  }

  .haeder-phone {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 1399px) {
  .haeder-phone {
    margin-left: 20px;
  }

  .site-header .navbar .pages-names a {
    margin: 0px 0 0 20px;
    font-size: 14px;
  }

  .header-blue-btn.btn_wrap button {
    margin: 0 0 0 20px;
  }

  .site-header .navbar {
    padding: 10px 0;
  }

  .site-main-logo {
    width: 153px;
  }
}

@media only screen and (max-width: 1199px) {
  .site-header .navbar .pages-names a {
    margin: 0 0 0 15px;
    font-size: 13px;
  }

  .logo-image {
    width: 200px;
  }

  .site-header.navbar .nav-link {
    font-size: 13px;
  }
}

@media only screen and (max-width: 991px) {


  .header-blue-btn.btn_wrap button {
    margin-right: 60px;
    margin-left: 0;
  }

  button.menu-burger-btn.btn.btn-primary {
    background: transparent;
    border: none;
    color: #344080;
    font-size: 30px;
    padding: 0;
  }

  .pages-names.desktop-nav {
    display: none !important;
  }

  .desktop-nav {
    display: none;
  }

  .mobile-menu-bar {
    display: flex;
  }

  .site-header .navbar-toggler {
    border: none;
    color: #fff;
    box-shadow: none !important;
    outline: none;
    position: absolute;
    right: 0;
    z-index: 99999;
  }

  .site-header .navbar-collapse {
    position: absolute;
    top: 0;
    width: 100%;
    background: #1A2A79;
    width: 116%;
    margin: 0 auto;
    padding: 10px 10px;
    z-index: 33333;
    width: 314px;
    transition: 0.1s;
    min-height: 100vh;
    right: -350px;
    padding-left: 25px;
    padding-top: 40px;
    -webkit-transition: 0.1s;
    -moz-transition: 0.1s;
    -ms-transition: 0.1s;
    -o-transition: 0.1s;
  }

  .collapse.show {
    right: -30px;
    width: 350px;
  }

  .navbar-toggler .navbar-toggler-icon {
    background-image: url(../../../assets/images/close.svg) !important;
  }

  .navbar-toggler.collapsed .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgb(72, 177, 56)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
  }

  .site-header .navbar a.active,
  .site-header .navbar a:hover {
    color: #267ddd !important;
  }

  .site-header .navbar .pages-names a {
    margin: 0 0 0 0;
    padding-top: 25px;
    font-size: 16px;
  }

  .site-header .navbar .pages-names a.active,
  .site-header .navbar .pages-names a:hover {
    color: #48b138 !important;
  }

  .site-header .navbar .pages-names a {
    color: #fff;
  }

  .site-header .navbar {
    padding: 10px 0;
  }

  .haeder-phone {
    margin-left: 0;
  }

  .haeder-phone {
    margin-right: 30px;
  }
  .pages-names.mobile-nav a.active {
    background: #fff;
    color: #344080  !important;
    border-radius: 10px;
}
}

@media only screen and (max-width: 567px) {
  .logo-image {
    width: 150px;
  }

  .btn_wrap span {
    padding: 12px 15px;
    font-size: 14px;
  }

  .mobile-nbr {
    display: none;
  }

  .haeder-phone {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 450px) {
  .btn_wrap span {
    padding: 10px 10px;
    font-size: 12px;
  }

  .header-blue-btn.btn_wrap button {
    margin-right: 70px;
  }

  .site-header .navbar-toggler {
    right: 0;
  }
}

@media only screen and (max-width: 567px) {
  .site-main-logo {
    width: 145px;
  }

  .logo-image {
    width: 150px;
  }

  .btn_wrap span {
    padding: 12px 15px;
    font-size: 14px;
  }
}