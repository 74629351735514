.cms-pages-baner {
  position: relative;
  height: 100%;
  min-height: 910px;
  position: relative;
  padding: 140px 0px;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
}

.cms-heading-content {
  max-width: 735px;
}

.cms-heading-content h4 {
  font-size: 35px;
  line-height: 35px;
  text-transform: uppercase;
  font-weight: 600;
}

.cms-heading-content h2 {
  font-size: 60px;
  font-weight: 600;
  line-height: 70px;
}

.cms-heading-content p {
  font-size: 24px;
  line-height: 35px;
  font-weight: 300;
  text-transform: capitalize;
}

.cable_tv_content img {
  width: 100%;
  border-top: 7px solid #162466;
  border-radius: 6px;
  margin-top: 60px;
  margin-bottom: 100px;
}

.tv_cable_banner {
  background: url(../../../assets/images/cabletv.png);
}

.internet_banner {
  background: url(../../../assets/images/internet.png);
}

.get-a-qoute_banner {
  background: url(../../../assets/images/qoute_banner.png);
  min-height: 78vh !important;
}

.phone_banner {
  background: url(../../../assets/images/phone-bg.png);
}

.cms-pages-baner.internet_banner {
  min-height: 910px;
  padding: 100px 0;
}

.cms-page-banner-button {
  margin-top: 40px;
}

.red_staric {
  color: #dc3545;
  padding-left: 5px;
}

.mobile-btn {
  display: none;
}

@media (max-width: 1780px) {
  .cms-pages-baner.internet_banner {
    min-height: 788px;
  }

  .cms-pages-baner.tv_cable_banner {
    min-height: 788px;
  }

  .cms-pages-baner {
    min-height: 788px;
  }
}

@media only screen and (max-width: 1600px) {
  .cms-heading-content p {
    font-size: 16px;
    line-height: 26px;
  }

  .cms-heading-content h2 {
    font-size: 50px;
    line-height: 60px;
  }

  .cms-pages-baner {
    padding: 150px 0px 100px;
  }

  .cms-pages-baner.tv_cable_banner {
    min-height: 680px;
    background-size: cover;
  }

  .cms-pages-baner.internet_banner {
    min-height: 680px;
    padding: 100px 0;
  }

  .cms-page-banner-button {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1399px) {
  .cms-pages-baner.tv_cable_banner {
    min-height: 600px;
    padding: 100px 0 0;
  }

  .cms-pages-baner.phone_banner {
    padding: 100px 0;
    min-height: 600px;
  }

  .cms-heading-content h4 {
    font-size: 26px;
    line-height: 34px;
  }

  .cms-heading-content h2 {
    font-size: 36px;
    line-height: 43px;
  }

  .get-a-qoute_banner {
    min-height: 78vh !important;
    padding: 100px 0;
  }

  .cms-heading-content p {
    font-size: 14px;
    line-height: 21px;
  }

  .cms-heading-content {
    max-width: 480px;
  }

  .cms-pages-baner.internet_banner {
    min-height: 600px;
  }

  .cms-page-banner-button {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 1199px) {
  .cms-pages-baner {
    background-size: cover !important;
    background-position: center;
    padding: 100px 0 50px;
  }

  .cms-pages-baner.phone_banner {
    padding: 73px 0;
    min-height: 460px;
  }

  .get-a-qoute_banner {
    min-height: 55vh !important;
  }

  .cms-pages-baner.phone_banner {
    padding: 60px 0;
    min-height: 460px;
  }
  .cms-pages-baner.tv_cable_banner {
    min-height: 600px;
    padding: 70px 0 0;
}
  .cms-heading-content h4 {
    font-size: 24px;
    line-height: 30px;
  }

  .cms-heading-content h2 {
    font-size: 33px;
    line-height: 38px;
  }

  .cms-heading-content p {
    font-size: 16px;
    line-height: 20px;
    padding-top: 10px;
  }

  .cms-pages-baner.internet_banner {
    min-height: 408px;
    padding: 50px 0 100px;
  }

  .cms-page-banner-button {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 992px) {
  .cms-pages-baner.phone_banner {
    padding: 65px 0;
    min-height: 497px;
  }
  .cms-pages-baner.internet_banner h2 {
    font-size: 28px;
  }
  .get-a-qoute_banner {
    min-height: 50vh !important;
    padding: 67px 0;
  }

  .cms-pages-baner.tv_cable_banner {
    min-height: 496px;
    object-fit: cover;
    background-position: center;
    padding: 70px 0 0;
  }
  .cms-heading-content h2 {
    font-size: 33px;
    line-height: 47px;
  }

  .cms-page-banner-button .theme-btn {
    height: 55px;
  }

  .cms-heading-content p {
    font-size: 14px;
    line-height: 18px;
  }

  .cms-pages-baner {
    background-size: cover !important;
    background-position: center;
    padding: 100px 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .cms-pages-baner {
    padding: 50px 0;
  }
}

@media only screen and (max-width: 575px) {
  .cms-pages-baner.tv_cable_banner {
    padding: 100px 0 0;
  }
  .cms-pages-baner.phone_banner {
    padding: 100px 0 0;
}
  .cms-pages-baner.get-a-qoute_banner {
    padding: 125px 0px;
  }

  .cms-pages-baner.internet_banner {
    padding: 101px 0px;
  }

  .desktop-btn {
    display: none;
  }

  .mobile-btn {
    display: block;
  }

  .cms-page-banner-button .theme-btn {
    width: 100%;
    margin: 0 auto;
  }

  .cms-pages-baner.phone_banner {
    min-height: 460px;
  }

  .cms-pages-baner {
    padding-bottom: 0;
  }

  .cms-heading-content {
    font-size: 25px;
    line-height: 38px;
    text-align: center;
  }

  .cms-heading-content h4 {
    font-size: 17px;
  }

  .cms-pages-baner.internet_banner .cms-heading-content h2 {
    font-size: 25px;
    line-height: 38px;
  }

  .cms-pages-baner.internet_banner .cms-heading-content h2 {
    font-size: 22px;
    line-height: 28px;
  }

  .cms-pages-baner.internet_banner {
    min-height: 358px;
    padding: 50px 0 100px;
  }

  .cms-page-banner-button.mobile-btn {
    display: flex;
    padding: 20px;
    margin: -50px 0 20px;
  }
}