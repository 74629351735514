@font-face {
  font-family: "Outfit";
  src: url("./assets/fonts/Outfit-ExtraLight.woff2") format("woff2"),
    url("./assets/fonts/Outfit-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Outfit";
  src: url("./assets/fonts/Outfit-ExtraBold.woff2") format("woff2"),
    url("./assets/fonts/Outfit-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Outfit";
  src: url("./assets/fonts/Outfit-Black.woff2") format("woff2"),
    url("./assets/fonts/Outfit-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Outfit";
  src: url("./assets/fonts/Outfit-Light.woff2") format("woff2"),
    url("./assets/fonts/Outfit-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Outfit";
  src: url("./assets/fonts/Outfit-Medium.woff2") format("woff2"),
    url("./assets/fonts/Outfit-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Outfit";
  src: url("./assets/fonts/Outfit-Regular.woff2") format("woff2"),
    url("./assets/fonts/Outfit-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Outfit";
  src: url("./assets/fonts/Outfit-SemiBold.woff2") format("woff2"),
    url("./assets/fonts/Outfit-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Outfit";
  src: url("./assets/fonts/Outfit-Thin.woff2") format("woff2"),
    url("./assets/fonts/Outfit-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Andasia Personal Use';
  src: url('./assets/fonts/AndasiaPersonalUse.woff2') format('woff2'),
    url('./assets/fonts/AndasiaPersonalUse.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


body {
  font-family: "Outfit";
  font-weight: normal;
  font-size: 18px;
  color: #000;
  min-height: 100vh;
}
.App {
  overflow: hidden;
}
.homepg-wraper {
  overflow: hidden;
}

.custom-container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 7.5%;
}

.gray-clr {
  color: #646464 !important;
}

.gray-light {
  color: #d0d0d0;
}

.theme-clr {
  color: #1a2a79;
}

.green-clr {
  color: #439037 !important;
}

ul {
  padding-left: 0;
}

ul,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

.btn,
a {
  -o-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -webkit-transition: all 0.3s ease-in-out;
}

/* theme button */
.theme-btn {
  /* background: #1A2A79; */
  /* padding: 18px 25px; */
  border-radius: 14px;
  text-transform: capitalize;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  color: #fff;
  padding: 15px 25px;
  background: #fff;
  color: #1a2a79;
  border-color: #1a2a79;
}

.theme-btn:hover {
  border-color: #1a2a79;
  color: #fff;
  background: #1a2a79;
}
.form_content_banner button.btn.theme-btn.d-flex {
  position: relative;
}
.baner-section input.form-control {
  position: relative;
}
.form-control.is-invalid{
  background-position: right calc(.375em + 0.875rem) center;
}
.footer-newsletter input.form-control:focus-visible,
.footer-newsletter input.form-control:focus  {
  border: none ;
  outline: none;
  box-shadow: none;
}

.cms-pages-baner .theme-btn:hover {
  border-color: #fff;
  color: #fff;
}

.green-borderd-btn {
  border: 1px solid #439037;
  border-radius: 14px;
  color: #439037;
  text-transform: capitalize;
  font-size: 24px;
  font-weight: 600;
  padding: 15px 25px;
}

.green-borderd-btn:hover {
  background-color: #439037;
  color: #fff;
}
.green-filled-btn{
  border: 1px solid #439037;
  border-radius: 14px;
  background:  #439037;
  color: #fff;
  text-transform: capitalize;
  font-size: 24px;
  font-weight: 600;
  padding: 15px 25px;
}
.green-filled-btn:hover {
  background: #fff;
  color: #439037;
  border-color: #439037;
}
.padding-top {
  padding-top: 100px;
}

.padding-bottom {
  padding-bottom: 100px;
}

h1 {
  font-size: 71px;
  line-height: 85px;
  font-weight: 900;
}

h2 {
  font-size: 76px;
  line-height: 86px;
  font-weight: 500;
}

h3 {
  font-size: 64px;
  line-height: 70px;
  font-weight: 500;
}

h4 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 600;
}

h5 {
  font-size: 34px;
  font-weight: 500;
  line-height: 43px;
}

h6 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
}

p {
  font-size: 18px;
  line-height: 30px;
}
.font-medium{font-weight: 600 !important;}
.grey-text{color: #555555;}
.font-bold{font-weight: 700;}
.font-normal{font-weight: 400;}
.yellow-text{color: #FFA62C;}
.green-text{color: #439037;}
.font-18{font-size: 18px;}
.dark-grey{color: #555555;}
/* check box */
.custom-control.overflow-checkbox .overflow-control-input {
  display: none;
}
.fs-17{
  font-size: 18px; 
 }
.custom-control.overflow-checkbox {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.cri-pointer{cursor: pointer;}
.custom-control.overflow-checkbox .overflow-control-input {
  display: none;
}

.custom-control.overflow-checkbox .overflow-control-indicator {
  border-radius: 3px;
  display: inline-block;
  position: absolute;
  top: 8px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #1a2a79;
  cursor: pointer;
}

.custom-control.overflow-checkbox .overflow-control-indicator::after {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  transition: 0.3s;
  transform: rotateZ(90deg) scale(0);
  width: 10px;
  border-bottom: 4px solid #1a2a79;
  border-right: 4px solid #1a2a79;
  border-radius: 3px;
  top: -2px;
  left: 2px;
}

.custom-control.overflow-checkbox .overflow-control-indicator::before {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  transition: 0.3s;
  width: 10px;
  border-right: 7px solid #fff;
  border-radius: 3px;
  transform: rotateZ(45deg) scale(1);
  top: -4px;
  left: 5px;
  opacity: 0;
}

.custom-control.overflow-checkbox .overflow-control-input:checked~.overflow-control-indicator::after {
  transform: rotateZ(45deg) scale(1);
  top: -6px;
  left: 5px;
}

.custom-control.overflow-checkbox .overflow-control-input:checked~.overflow-control-indicator::before {
  opacity: 1;
}

.custom-control.overflow-checkbox .overflow-control-input:disabled~.overflow-control-indicator {
  opacity: 0.5;
  border: 2px solid #1a2a79;
}

.custom-control.overflow-checkbox .overflow-control-input:disabled~.overflow-control-indicator:after {
  border-bottom: 4px solid #1a2a79;
  border-right: 4px solid #1a2a79;
}

.form-control:focus,.form-select:focus{
  box-shadow: 0 0 0 0.1rem rgba(67, 144, 55);
}
label.form-label {
  font-weight: 600;
  letter-spacing: 0px;
  color: #777777;
  text-transform: capitalize;
  font-size: 16px;
}
.form-control,.form-select {
  height: 60px;
  border: 1px solid #E4E4E4;
  border-radius: 8px;
}

::placeholder {
  letter-spacing: 0px;
  color: #B7B7B7;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 300;
}
textarea.form-control{
  height: 150px;
  resize: none;
}
.pl-25{
  padding-left: 30px;
}
span.overflow-control-description.pl-25 {
  color: #1A2A79;
  text-transform: capitalize;
  font-weight: bold;
  font-size: 24px;
  line-height: 14px;
}
.optimum-banner a{
  text-decoration: none;
}

/* modal styling */

.modal-content {
  box-shadow: 0 0 15px #00000029;
  border: 1px solid #707070;
  border-radius: 26px;
  color: #58595a;
  font-size: 16px;
  line-height: 22px;
}
.modal-header .btn-close {
  width: 32px;
  height: 32px;
  background: #1a2a79;
  opacity: 1;
  color: #fff;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-header .btn-close {
  background-image: url(./assets/images/cross-icon.svg) !important;
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
}
h4.privacy-sub-heading {
  font-size: 30px;
  color: #1a2a79;
}
.green-rounded-btn {
  padding: 10px 12px;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  background: #439037;
  color: #fff;
  border-color: transparent;
}
.green-rounded-btn:hover{
  background: transparent;
  color: #439037;
  border-color: #439037;
}
@media only screen and (max-width: 1800px) {
  .green-filled-btn,.theme-btn {
    font-size: 18px;
    padding: 12px 25px;
    line-height: 20px;
}
  .padding-top {
    padding-top: 100px;
  }

  .padding-bottom {
    padding-bottom: 100px;
  }

  p {
    font-size: 16px;
    line-height: 26px;
  }

  h3 {
    font-size: 50px;
    line-height: 55px;
  }

  h4 {
    font-size: 30px;
    line-height: 36px;
  }

  h5 {
    font-size: 31px;
    line-height: 38px;
  }

  h6 {
    font-size: 22px;
    line-height: 26px;
    font-weight: 500;
  }

  .theme-btn,
  .green-borderd-btn {
    padding: 11px 20px;
    font-size: 20px;
    height: unset;
  }
}

@media only screen and (max-width: 1500px) {
  h4.privacy-sub-heading {
    font-size: 25px;
}
  .font-bold {
    font-weight: 600;
}
  .custom-container {
    padding: 0 6.7%;
  }

  .theme-btn,
  .green-borderd-btn {
    font-size: 16px;
  }

  h1 {
    font-size: 60px;
    line-height: 70px;
  }

  h2 {
    font-size: 55px;
    line-height: 56px;
  }

  p {
    font-size: 15px;
    line-height: 20px;
  }
  .custom-control.overflow-checkbox .overflow-control-indicator{top: 3px;}
}

@media only screen and (max-width: 1399px) {
  .custom-container {
    padding: 0 5.5%;
  }

  h3 {
    font-size: 46px;
    line-height: 50px;
  }

  h4 {
    font-size: 28px;
    line-height: 34px;
  }

  h5 {
    font-size: 27px;
    line-height: 35px;
  }

}

@media only screen and (max-width: 1199px) {
  h4.privacy-sub-heading {
    font-size: 20px;
  }
  .green-filled-btn ,.theme-btn{
    font-size: 15px;
    border-radius: 6px;
    padding: 9px 12px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
}
  label.form-label{
    font-size: 14px;
  }
  .custom-container {
    padding: 0 4%;
  }

  .padding-bottom {
    padding-bottom: 70px;
  }

  .padding-top {
    padding-top: 70px;
  }

  h1 {
    font-size: 53px;
    line-height: 65px;
  }

  h2 {
    font-size: 50px;
    line-height: 57px;
  }

  h3 {
    font-size: 40px;
    line-height: 50px;
  }

  h4 {
    font-size: 27px;
    line-height: 34px;
  }

  p {
    font-size: 14px;
    line-height: 18px;
  }

  h6 {
    font-size: 20px;
    line-height: 26px;
  }
}

@media only screen and (max-width: 992px) {
  .lead-img-container img {
    width: 80%;
    margin: 0 auto;
    display: flex;
  }

  h1 {
    font-size: 45px;
    line-height: 60px;
  }

  h2 {
    font-size: 42px;
    line-height: 50px;
  }
}

@media only screen and (max-width: 767px) {

  h4 {
    font-size: 23px;
    line-height: 29px;
  }

  h2 {
    font-size: 35px;
    line-height: 40px;
  }

  h1 {
    font-size: 32px;
    line-height: 50px;
  }

  h3 {
    font-size: 31px;
    line-height: 36px;
  }
  .comlinks-links {
    padding: 40px 0 20px;
    margin-top: 15px;
}
.map-section {
  padding: 34px 0;
}
}

@media only screen and (max-width: 600px) {
  .font-bold {
    font-weight: 500;
}
  .padding-top {
    padding-top: 45px;
  }
  .padding-bottom {
    padding-bottom: 40px;
}
  h1 {
    font-size: 30px;
    line-height: 42px;
  }

  h2 {
    font-size: 26px;
    line-height: 35px;
  }

  h4 {
    font-size: 18px;
    line-height: 24px;
  }

  .custom-container {
    padding: 0 6%;
  }

  h5 {
    font-size: 20px;
    line-height: 29px;
  }

  .theme-btn,
  .green-borderd-btn {
    padding: 7px 15px;
    font-size: 20px;
  }
  .payment-detail-modal h5 {
    font-size: 16px;
  }

 
}
@media only screen and (max-width: 575px) {
  .baner-btn .theme-btn,
  .baner-btn .green-borderd-btn {
    width: 100%;
  }
}
@media (max-width:480px){
  .fs-17 {
    font-size: 14px;
}
}
/* .custom-dropdown{
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 10px;
  border: none;
  padding: 11px 30px;
  color: #888888;
  font-size: 18px;
  font-weight: 300;
} */
.css-13cymwt-control{
  background-color: #FFFFFF;
  box-shadow: 0px 0px 10px #0000001a;
  border-radius: 10px;
  border: none;
  padding: 11px 30px;
  color: #888888;
  font-size: 18px;
  font-weight: 300;
  border: none !important;
  border-radius: 10px !important;
}