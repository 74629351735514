.form_image{
    position: relative;
    background-image: url(../../../assets/images/qoute-form.png);
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100%;
    border-radius: 26px;
}
.form_content label {
    font-size: 20px;
    color: #1A2A79;
    font-weight: 300;
    margin-bottom: 10px;
    line-height: 26px;
    font-weight: 600;
}
.form_content .form-control {
    background-color: #FFFFFF; ;
    box-shadow: 0px 0px 10px #0000001A;
    border-radius: 10px;
    border: none;
    padding: 18px 12px;
    color: #888888;
    font-size: 18px;
}
.qoute__form {
    padding: 20px 75px 20px 20px;
    box-shadow: 0px 0px 20px #00000012;
    border-radius: 30px;
    opacity: 1;
}
.form_content {
    padding-top: 74px;
    padding-left: 40px;
}
.form_image img {
    border-radius: 27px;
    width: 100%;
}
.form_content select {
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px #0000001A;
    border-radius: 10px;
    border: none;
    padding: 18px 30px;
    margin-top: 40px;
    color: #1A2A79;
    text-transform: capitalize;
    font-size: 20px;
    font-weight: 300;
    appearance: none;
    background: url(../../../assets/images/downarrow.svg);
    background-repeat: no-repeat;
    background-position-x: calc(100% - 30px);
    background-position-y: calc(100% - 28px);
    font-weight: 600;
}
.Submitting_form {
    color: #161616;
    text-transform: capitalize;
    font-size: 18px;
    font-weight: 300;
    margin-left: 27px;
}
.terms_condition {
    margin-top: 40px;
}
.qoute__form {
    margin: 70px 0px 120px 0px;
}
.updated-qoute-content .top-green-border {
    border-top: 0;
    border: 5px solid #439037;
}
.updated-qoute-content .grey-bg-card-content {
    background: #205c952e;
    padding: 30px;
}
.updated-qoute-content .grey-bg-card {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    max-width: 100%;
    padding: 40px;
}
.updated-qoute-content .qoute__form {
    padding: 20px 20px 20px 20px;
}
button.btn.quote-agree-btn {
    background: #03a84e;
    color: #fff;
    min-width: 150px;
    border: 1px solid #03a84e;
}
button.btn.quote-agree-btn:hover{
background-color: #fff;
border: 1px solid #03a84e ;
color: #03a84e;
font-size: 21px;
}
.updated-qoute-content h5{
margin-bottom: 17px;
color: #439037;
font-size: 33px;
border-bottom: 1px solid;
padding-bottom: 6px;
}
.updated-qoute-content .qoute__form {
    padding: 0;
}
.generic-quote-divider{
border-bottom: 1px solid #00000021;
padding: 4px 0 10px 0;
}
@media only screen and (max-width: 1600px) {
    .form_content label {
        font-size: 16px;
        line-height: 21px;
    }
    .form_content .form-control,.form_content select{
        padding: 15px 13px;
        font-size: 16px;
    }
    .form_content select{
        margin-top: 20px;
        background-position-x: calc(100% - 19px);
        background-position-y: calc(100% - 20px);
    }
    .Submitting_form {
        font-size: 16px;
        line-height: 22px;
    }
    .form_content {
        padding-top: 50px;
    }
}
@media only screen and (max-width: 1367px) {
    .form_content label {
        font-size: 15px;
        line-height: 18px;
    }
    .form_content .form-control,.form_content select{
        padding: 12px 13px;
        font-size: 15px;
    }
    .form_content select {
        margin-top: 20px;
        background-position-x: calc(100% - 14px);
        background-position-y: calc(100% - 18px);
    }
    .Submitting_form {
        font-size: 14px;
        line-height: 18px;
    }
    .form_content {
        padding-top: 40px;
    }
    .terms_condition {
        margin-top: 20px;
    }
}
@media only screen and (max-width: 1199px) {
    .form_content label {
        font-size: 14px;
    }
    .form_content .form-control,.form_content select{
        padding: 10px 10px;
        font-size: 14px;
    }
    .form_content select {
        margin-top: 15px;
        background-position-y: calc(100% - 14px);
    }
    .Submitting_form {
        font-size: 13px;
        line-height: 16px;
    }
    .form_content {
        padding: 50px;
    }
    .terms_condition {
        margin-top: 16px;
    }
    .form_image {
        display: none;
    }
    .qoute__form{
        padding: 0;
        margin: 20px 0px 100px 0px;
    }
  
}


@media only screen and (max-width: 767px) {
    .form_content {
        padding: 25px;
    }
   
}
@media only screen and (max-width: 575px) {
    .form_content {
        padding: 25px 10px;
    }
    .form_content .form-control, .form_content select,.form_content label{
        font-size: 12px;
    }
    .updated-qoute-content .grey-bg-card {
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
        max-width: 100%;
        padding: 0px
    }
    button.btn.quote-agree-btn {
        min-width: 133px;
        font-size: 16px;
    }
    .updated-qoute-content .grey-bg-card-content {
        padding: 11px 15px;
    }
    .updated-qoute-content h5{
        margin-bottom: 10px;
        font-size: 22px;
        }
        .updated-qoute-content .custom-container {
            padding: 0 3%;
        }
        .updated-qoute-content .qoute__form {
            padding: 7px;
            border-radius: 4px;
        }
}
